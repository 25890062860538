import React, { useState, useEffect } from 'react';
import { 
  BarChart, Bar, PieChart, Pie, LineChart, Line,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell,
  ResponsiveContainer 
} from 'recharts';
import { 
  Users, Clock, Download, Globe, ArrowLeft,
  CheckSquare, AlertCircle, ExternalLink 
} from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPollAnalytics } from '../services/firestore';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

const PollAnalytics = () => {
  const { pollId } = useParams();
  const navigate = useNavigate();
  const [pollData, setPollData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    if (pollId) {
      fetchPollData();
    }
  }, [pollId]);

  const fetchPollData = async () => {
    try {
      const data = await getPollAnalytics(pollId);
      setPollData(data);
    } catch (error) {
      console.error('Error fetching poll analytics:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    return new Date(timestamp.seconds * 1000).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const exportAnalytics = () => {
    const data = {
      question: pollData.question,
      options: pollData.options,
      votes: pollData.analytics.optionVotes,
      trends: pollData.analytics.votingTrends,
      created: formatDate(pollData.createdAt),
      status: pollData.status,
      totalVotes: pollData.totalVotes
    };

    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `poll-analytics-${pollId}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary 
                      dark:border-yellow-500"></div>
      </div>
    );
  }

  if (!pollData) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-500 dark:text-gray-400">Poll not found</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header */}
      <div className="mb-8">
        <button
          onClick={() => navigate('/polls')}
          className="flex items-center gap-2 text-gray-600 dark:text-gray-400 
                   hover:text-gray-900 dark:hover:text-gray-100 mb-4"
        >
          <ArrowLeft size={20} />
          Back to Polls
        </button>
        <div className="flex justify-between items-start">
          <div>
            <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-2">
              {pollData.question}
            </h1>
            <div className="flex items-center gap-4 text-sm text-gray-500 dark:text-gray-400">
              <div className="flex items-center gap-1">
                <Clock size={16} />
                {formatDate(pollData.createdAt)}
              </div>
              <div className="flex items-center gap-1">
                <Users size={16} />
                {pollData.totalVotes} votes
              </div>
              {pollData.isAnonymous && (
                <div className="flex items-center gap-1">
                  <AlertCircle size={16} />
                  Anonymous
                </div>
              )}
              {pollData.allowMultipleAnswers && (
                <div className="flex items-center gap-1">
                  <CheckSquare size={16} />
                  Multiple answers
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-4">
            <button
              onClick={exportAnalytics}
              className="flex items-center gap-2 px-4 py-2 text-gray-700 dark:text-gray-300 
                       hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md"
            >
              <Download size={20} />
              Export
            </button>
            {pollData.telegramMessageId && (
              <a
                href={`https://t.me/c/${pollData.telegramChatId}/${pollData.telegramMessageId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 px-4 py-2 text-primary dark:text-yellow-500 
                         hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md"
              >
                View in Telegram
                <ExternalLink size={20} />
              </a>
            )}
          </div>
        </div>
      </div>

      {/* Analytics Content */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Voting Distribution */}
        <div className="bg-white dark:bg-darkbg rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
            Vote Distribution
          </h2>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={pollData.options.map((option, index) => ({
                    name: option,
                    value: pollData.analytics.optionVotes[`option${index + 1}`] || 0
                  }))}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {pollData.options.map((_, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Voting Trends */}
        <div className="bg-white dark:bg-darkbg rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
            Voting Trends
          </h2>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={pollData.analytics.votingTrends}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="timestamp" 
                  tickFormatter={(timestamp) => {
                    return new Date(timestamp).toLocaleDateString();
                  }}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="votes"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PollAnalytics;
