import React, { useState, useEffect } from 'react';
import { 
  BarChart2, 
  Trash2, 
  ExternalLink, 
  Clock,
  Users,
  CheckSquare,
  AlertCircle
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import DeletePollModal from './DeletePollModal';
import { getPollAnalytics } from '../services/firestore';
import { usePoll } from '../contexts/PollContext';
import { useAuth } from '../../auth/contexts/AuthContext';

const PollCard = ({ poll }) => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { fetchPolls } = usePoll();
  const { user } = useAuth();

  const handleDeleteSuccess = () => {
    setShowDeleteModal(false);
    // Update polls through context instead of page refresh
    if (user) {
      fetchPolls(user.uid);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'active':
        return 'text-green-500 bg-green-50 dark:bg-green-900/20';
      case 'closed':
        return 'text-red-500 bg-red-50 dark:bg-red-900/20';
      default:
        return 'text-gray-500 bg-gray-50 dark:bg-gray-900/20';
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    return new Date(timestamp.seconds * 1000).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  useEffect(() => {
    if (poll.status === 'active') {
      const interval = setInterval(async () => {
        try {
          const updatedPoll = await getPollAnalytics(poll.id);
          // Update local poll data with new analytics
          if (updatedPoll.totalVotes !== poll.totalVotes) {
            fetchPolls(user.uid);
          }
        } catch (error) {
          console.error('Error refreshing poll data:', error);
        }
      }, 30000); // Check every 30 seconds
      
      return () => clearInterval(interval);
    }
  }, [poll.id, poll.status]);

  return (
    <div className="bg-white dark:bg-darkbg rounded-lg shadow-sm border border-gray-200 
                    dark:border-gray-700 p-6 transition-all hover:shadow-md">
      {/* Header */}
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">
          {poll.question}
        </h3>
        <div className="flex items-center gap-2">
          <button
            onClick={() => navigate(`/polls/${poll.id}`)}
            className="p-2 text-gray-600 hover:text-primary dark:text-gray-400 
                     dark:hover:text-yellow-500 rounded-full hover:bg-gray-100 
                     dark:hover:bg-gray-800"
            title="View Analytics"
          >
            <BarChart2 size={20} />
          </button>
          <button
            onClick={() => setShowDeleteModal(true)}
            className="p-2 text-gray-600 hover:text-red-600 dark:text-gray-400 
                     dark:hover:text-red-400 rounded-full hover:bg-gray-100 
                     dark:hover:bg-gray-800"
            title="Delete Poll"
          >
            <Trash2 size={20} />
          </button>
        </div>
      </div>

      {/* Poll Info */}
      <div className="space-y-4">
        {/* Options Preview */}
        <div className="space-y-2">
          {poll.options.slice(0, 3).map((option, index) => (
            <div key={index} className="text-sm text-gray-600 dark:text-gray-400">
              • {option}
            </div>
          ))}
          {poll.options.length > 3 && (
            <div className="text-sm text-gray-500 dark:text-gray-500">
              +{poll.options.length - 3} more options
            </div>
          )}
        </div>

        {/* Stats */}
        <div className="flex items-center gap-4 text-sm">
          <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400">
            <Users size={16} />
            <span>{poll.totalVotes || 0} votes</span>
          </div>
          <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400">
            <Clock size={16} />
            <span>{formatDate(poll.createdAt)}</span>
          </div>
          {poll.isAnonymous && (
            <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400">
              <AlertCircle size={16} />
              <span>Anonymous</span>
            </div>
          )}
          {poll.allowMultipleAnswers && (
            <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400">
              <CheckSquare size={16} />
              <span>Multiple answers</span>
            </div>
          )}
        </div>

        {/* Status Badge */}
        <div className="flex items-center justify-between">
          <span className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(poll.status)}`}>
            {poll.status.charAt(0).toUpperCase() + poll.status.slice(1)}
          </span>
          {poll.telegramMessageId && (
            <a
              href={`https://t.me/c/${poll.telegramChatId}/${poll.telegramMessageId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-1 text-sm text-primary dark:text-yellow-500 
                       hover:text-primary-dark dark:hover:text-yellow-400"
            >
              View in Telegram
              <ExternalLink size={16} />
            </a>
          )}
        </div>
      </div>

      <DeletePollModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDeleteSuccess={handleDeleteSuccess}
        pollId={poll.id}
        pollQuestion={poll.question}
      />
    </div>
  );
};

export default PollCard;
