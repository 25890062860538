import React, { createContext, useContext, useState, useCallback } from 'react';
import { getUserPolls, createPoll, deletePoll, updatePollWithTelegramData } from '../services/firestore';

const PollContext = createContext();

export const PollProvider = ({ children }) => {
  const [polls, setPolls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPolls = useCallback(async (userId) => {
    setLoading(true);
    try {
      const userPolls = await getUserPolls(userId);
      setPolls(userPolls);
      setError(null);
    } catch (err) {
      setError('Failed to fetch polls');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const createNewPoll = async (pollData) => {
    setLoading(true);
    try {
      const pollId = await createPoll(pollData);
      await fetchPolls(pollData.createdBy); // Refresh polls after creation
      return pollId;
    } catch (err) {
      setError('Failed to create poll');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const value = {
    polls,
    loading,
    error,
    fetchPolls,
    createNewPoll,
    deletePoll
  };

  return (
    <PollContext.Provider value={value}>
      {children}
    </PollContext.Provider>
  );
};

export const usePoll = () => {
  const context = useContext(PollContext);
  if (!context) {
    throw new Error('usePoll must be used within a PollProvider');
  }
  return context;
};
