import React, { useState, useEffect } from 'react';
import { 
  BarChart, Bar, PieChart, Pie, LineChart, Line,
  XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend as RechartsLegend, Cell,
  ResponsiveContainer 
} from 'recharts';
import { 
  Globe, Clock, Trash2, Copy, Check, ExternalLink, ArrowRight, Monitor, MapPin, Activity, Download 
} from 'lucide-react';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { getLinkAnalytics } from '../utils/shortApi';
import { QRCodeSVG } from 'qrcode.react';
import { Chart as ChartJS } from 'chart.js/auto';
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend as ChartLegend,
  ArcElement
} from 'chart.js';
import { Line as ChartLine, Pie as ChartPie } from 'react-chartjs-2';

// Register the chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  ChartLegend,
  ArcElement
);

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

const processAnalyticsData = (rawData) => {
  if (!rawData) return null;

  // Get the click statistics data
  const clickData = rawData.clickStatistics?.datasets[0]?.data || [];
  
  // Get creation date and calculate start date (2 days before)
  const creationDate = new Date(rawData.interval.startDate);
  const startDate = new Date(creationDate);
  startDate.setDate(creationDate.getDate() - 2);

  // Filter and map the click data
  const clickHistory = clickData
    .filter(item => new Date(item.x) >= startDate)
    .map(item => ({
      date: item.x.split('T')[0],
      clicks: parseInt(item.y)
    }));

  // Process referrers (from referer array)
  const referrers = rawData.referer?.length > 0 
    ? rawData.referer.map(ref => ({
        name: ref.social || 'Direct',
        value: ref.score
      }))
    : [{ name: 'Direct', value: rawData.totalClicks }];

  // Process operating systems
  const os = rawData.os?.map(item => ({
    name: item.os || 'Unknown',
    value: item.score
  })) || [];

  // Calculate peak hours (we'll need to implement this differently since it's not in the API response)
  const peakHours = []; // This will need to be calculated if needed

  // Calculate popular days (we'll need to implement this differently)
  const popularDays = []; // This will need to be calculated if needed

  // Growth metrics are already provided in a different format
  const weeklyGrowth = rawData.totalClicksChange === "999" ? 0 : parseInt(rawData.totalClicksChange);
  const monthlyGrowth = 0; // Not provided in current API response

  // Process locations data
  const locations = {
    countries: rawData.country?.map(item => ({
      name: item.countryName || 'Unknown',
      code: item.country || '',
      value: item.score || 0
    })) || [],
    cities: rawData.city?.map(item => ({
      name: item.name || 'Unknown',
      code: item.city || '',
      countryCode: item.countryCode || '',
      value: item.score || 0
    })) || []
  };

  // Process devices data
  const devices = {
    browsers: rawData.browser?.map(item => ({
      name: item.browser || 'Unknown',
      value: item.score || 0
    })) || [],
    os: rawData.os?.map(item => ({
      name: item.os || 'Unknown',
      value: item.score || 0
    })) || []
  };

  return {
    clickHistory,
    creationDate: creationDate.toISOString().split('T')[0], // Store creation date for reference
    peakHours,
    popularDays,
    weeklyGrowth,
    monthlyGrowth,
    referrers,
    os,
    totalClicks: rawData.totalClicks,
    humanClicks: rawData.humanClicks,
    totalClicksChange: rawData.totalClicksChange,
    locations,
    devices,
  };
};

const AnalyticsDashboard = ({ urlData, onDelete }) => {
  const [analytics, setAnalytics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');
  const [dateRange, setDateRange] = useState('7d');
  const [isRealTimeEnabled, setIsRealTimeEnabled] = useState(true);
  const [rawAnalytics, setRawAnalytics] = useState(null);

  useEffect(() => {
    const fetchAnalytics = async () => {
      if (!urlData.linkId) {
        console.error('No linkId available');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const data = await getLinkAnalytics(urlData.linkId);
        setRawAnalytics(data);
        const processedData = processAnalyticsData(data);
        setAnalytics(processedData);
      } catch (error) {
        console.error('Failed to fetch analytics:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAnalytics();

    let interval;
    if (isRealTimeEnabled) {
      interval = setInterval(fetchAnalytics, 30000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [urlData.linkId, isRealTimeEnabled]);

  useEffect(() => {
    if (rawAnalytics) {
      const processedData = processAnalyticsData(rawAnalytics);
      setAnalytics(processedData);
    }
  }, [dateRange, rawAnalytics]);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, 'trackedUrls', urlData.id));
      onDelete();
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting URL:', error);
      alert('Failed to delete URL');
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    
    // Handle Firestore Timestamp
    if (timestamp.seconds) {
      return new Date(timestamp.seconds * 1000).toLocaleString();
    }
    
    // Handle JavaScript Date object
    if (timestamp instanceof Date) {
      return timestamp.toLocaleString();
    }
    
    return 'N/A';
  };

  const renderChart = (chartData, type) => {
    if (!chartData || !chartData.datasets || !chartData.labels) {
      return <div className="text-center text-gray-500">No data available</div>;
    }

    switch (type) {
      case 'line':
        return (
          <ChartLine
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'bottom',
                  labels: {
                    padding: 20,
                    color: 'rgb(156 163 175)'
                  }
                },
                annotation: {
                  annotations: chartData.annotations
                }
              },
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    stepSize: 1,
                    color: 'rgb(156 163 175)'
                  },
                  grid: {
                    color: 'rgba(156, 163, 175, 0.1)'
                  }
                },
                x: {
                  ticks: {
                    color: 'rgb(156 163 175)'
                  },
                  grid: {
                    color: 'rgba(156, 163, 175, 0.1)'
                  }
                }
              }
            }}
            height={200}
          />
        );

      case 'pie':
        return (
          <ChartPie
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'bottom',
                  labels: {
                    padding: 20,
                    color: '#F59E0B'
                  }
                }
              }
            }}
            height={200}
          />
        );

      default:
        return null;
    }
  };

  const prepareChartData = (dataType) => {
    if (!analytics) return null;

    switch (dataType) {
      case 'clickHistory': {
        const data = analytics.clickHistory || [];
        const creationDate = analytics.creationDate;
        
        // Filter data based on selected date range
        const now = new Date();
        const filteredData = data.filter(item => {
          const itemDate = new Date(item.date);
          switch (dateRange) {
            case '24h':
              const yesterday = new Date(now);
              yesterday.setHours(now.getHours() - 24);
              return itemDate >= yesterday;
            case '7d':
              const lastWeek = new Date(now);
              lastWeek.setDate(now.getDate() - 7);
              return itemDate >= lastWeek;
            case '30d':
              const lastMonth = new Date(now);
              lastMonth.setDate(now.getDate() - 30);
              return itemDate >= lastMonth;
            case 'all':
            default:
              return true;
          }
        });

        // Check if dark mode is enabled
        const isDarkMode = document.documentElement.classList.contains('dark');

        return {
          labels: filteredData.map(item => {
            const date = new Date(item.date);
            return date.toLocaleDateString('en-US', { 
              month: 'short', 
              day: 'numeric'
            });
          }),
          datasets: [{
            label: 'Clicks',
            data: filteredData.map(item => item.clicks),
            borderColor: isDarkMode ? '#ffffff' : '#0a213e',
            backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(10, 25, 41, 0.1)',
            tension: 0.4,
            fill: true,
            pointRadius: 4,
            pointHoverRadius: 6,
            borderWidth: 2 // Added to make the line more visible
          }],
          annotations: {
            xaxis: [{
              x: new Date(creationDate).getTime(),
              borderColor: '#4CAF50',
              borderWidth: 2,
              label: {
                text: 'Created',
                style: {
                  color: '#4CAF50'
                }
              }
            }]
          }
        };
      }
      
      case 'referrers': {
        const data = analytics.referrers || [];
        const colors = ['#2196f3', '#ff9800', '#4caf50', '#f44336', '#9c27b0'];
        return {
          labels: data.map(item => item.name || 'Direct'),
          datasets: [{
            data: data.map(item => item.value),
            backgroundColor: colors.slice(0, data.length)
          }]
        };
      }
      
      case 'os': {
        const data = analytics.devices?.os || [];
        const colors = ['#3f51b5', '#e91e63', '#009688', '#ffc107', '#607d8b'];
        return {
          labels: data.map(item => item.name),
          datasets: [{
            data: data.map(item => item.value),
            backgroundColor: colors.slice(0, data.length)
          }]
        };
      }
      
      case 'browsers': {
        const data = analytics.devices?.browsers || [];
        const colors = ['#2196f3', '#ff9800', '#4caf50', '#f44336', '#9c27b0'];
        return {
          labels: data.map(item => item.name),
          datasets: [{
            data: data.map(item => item.value),
            backgroundColor: colors.slice(0, data.length)
          }]
        };
      }
      
      default:
        return {
          labels: [],
          datasets: [{
            data: [],
            label: 'No Data',
            backgroundColor: [],
            borderColor: '#2196f3'
          }]
        };
    }
  };

  const getDisplayUrl = () => {
    return urlData.shortUrl || urlData.trackingUrl;
  };

  const openUrl = (url) => {
    if (!url) return;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const renderOverviewTab = () => {
    if (!analytics) return <div>No overview data available</div>;

    // Calculate days since creation
    const startDate = urlData.createdAt?.seconds ? new Date(urlData.createdAt.seconds * 1000) : new Date();
    const currentDate = new Date();
    const daysSinceCreation = Math.ceil((currentDate - startDate) / (1000 * 60 * 60 * 24));

    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
        <div className="bg-gray-50 dark:bg-[#0a213e] p-4 rounded-lg">
          <h3 className="text-sm font-medium text-[#0A1929] dark:text-white">Click Statistics</h3>
          <div className="flex justify-between py-2">
            <span className="text-[#0A1929]/70 dark:text-white/70">Total Clicks</span>
            <span className="font-medium text-[#0A1929] dark:text-white">{analytics.totalClicks}</span>
          </div>
          <div className="flex justify-between py-2">
            <span className="text-[#0A1929]/70 dark:text-white/70">Human Clicks</span>
            <span className="font-medium text-[#0A1929] dark:text-white">{analytics.humanClicks}</span>
          </div>
          <div className="flex justify-between py-2">
            <span className="text-[#0A1929]/70 dark:text-white/70">Click Change (24h)</span>
            <span className={`font-medium ${
              analytics.totalClicksChange > 0 
                ? 'text-green-600 dark:text-green-400' 
                : analytics.totalClicksChange < 0 
                  ? 'text-red-600 dark:text-red-400' 
                  : 'text-[#0A1929] dark:text-white'
            }`}>
              {analytics.totalClicksChange === "999" 
                ? 'No previous data'
                : analytics.totalClicksChange === 0
                  ? 'No change'
                  : `${analytics.totalClicksChange > 0 ? '+' : ''}${analytics.totalClicksChange}%`
              }
            </span>
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-[#0a213e] p-4 rounded-lg">
          <h3 className="text-sm font-medium text-[#0A1929] dark:text-white">Time Period</h3>
          <div className="flex justify-between py-2">
            <span className="text-[#0A1929]/70 dark:text-white/70">Start Date</span>
            <span className="font-medium text-[#0A1929] dark:text-white">
              {formatDate(urlData.createdAt)}
            </span>
          </div>
          <div className="flex justify-between py-2">
            <span className="text-[#0A1929]/70 dark:text-white/70">End Date</span>
            <span className="font-medium text-[#0A1929] dark:text-white">Ongoing</span>
          </div>
          <div className="flex justify-between py-2">
            <span className="text-[#0A1929]/70 dark:text-white/70">Duration</span>
            <span className="font-medium text-[#0A1929] dark:text-white">
              {daysSinceCreation} days
            </span>
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-[#0a213e] p-4 rounded-lg">
          <h3 className="text-sm font-medium text-[#0A1929] dark:text-white">Performance Metrics</h3>
          <div className="flex justify-between py-2">
            <span className="text-[#0A1929]/70 dark:text-white/70">Avg. Clicks/Day</span>
            <span className="font-medium text-[#0A1929] dark:text-white">
              {(analytics.totalClicks / daysSinceCreation).toFixed(1)}
            </span>
          </div>
          <div className="flex justify-between py-2">
            <span className="text-[#0A1929]/70 dark:text-white/70">Bot Click Rate</span>
            <span className="font-medium text-[#0A1929] dark:text-white">
              {((analytics.totalClicks - analytics.humanClicks) / analytics.totalClicks * 100).toFixed(1)}%
            </span>
          </div>
          <div className="flex justify-between py-2">
            <span className="text-[#0A1929]/70 dark:text-white/70">Peak Day</span>
            <span className="font-medium text-[#0A1929] dark:text-white">
              {analytics.peakClickDay ? formatDate(analytics.peakClickDay) : 'N/A'}
            </span>
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-[#0a213e] p-4 rounded-lg">
          <h3 className="text-sm font-medium text-[#0A1929] dark:text-white mb-3">QR Code</h3>
          <div className="flex flex-col items-center">
            <div className="bg-white p-4 rounded-md">
              <QRCodeSVG 
                id="url-qr-code"
                value={urlData.shortUrl} 
                size={128}
                level="H"
                includeMargin={true}
              />
            </div>
            <button
              onClick={downloadQRCode}
              className="mt-3 w-full px-4 py-2 bg-primary text-white dark:bg-yellow-500 dark:text-black rounded-md hover:opacity-90 flex items-center justify-center gap-2"
            >
              <Download size={16} />
              Download QR Code
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderDevicesTab = () => {
    if (isLoading) {
      return <div className="text-center py-4">Loading device data...</div>;
    }

    if (!analytics || !analytics.devices) {
      return <div className="text-center py-4">No device data available</div>;
    }

    const { browsers, os } = analytics.devices;

    return (
      <div className="space-y-6">
        {/* Operating Systems Section */}
        <div className="bg-gray-50 dark:bg-[#0a213e] p-4 rounded-lg">
          <h3 className="text-sm font-medium text-[#0A1929] dark:text-white mb-3">Operating Systems</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-medium text-[#0A1929]/70 dark:text-white/70">OS</th>
                  <th className="px-4 py-2 text-right text-sm font-medium text-[#0A1929]/70 dark:text-white/70">Clicks</th>
                  <th className="px-4 py-2 text-right text-sm font-medium text-[#0A1929]/70 dark:text-white/70">Percentage</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                {os.map((item, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <div className="flex items-center gap-2 text-[#0A1929] dark:text-white">
                        <Monitor className="w-4 h-4" />
                        {item.name}
                      </div>
                    </td>
                    <td className="px-4 py-2 text-right text-[#0A1929] dark:text-white">{item.value}</td>
                    <td className="px-4 py-2 text-right text-[#0A1929] dark:text-white">
                      {((item.value / analytics.totalClicks) * 100).toFixed(1)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Browsers Section */}
        <div className="bg-gray-50 dark:bg-[#0a213e] p-4 rounded-lg">
          <h3 className="text-sm font-medium text-[#0A1929] dark:text-white mb-3">Browsers</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-medium text-[#0A1929]/70 dark:text-white/70">Browser</th>
                  <th className="px-4 py-2 text-right text-sm font-medium text-[#0A1929]/70 dark:text-white/70">Clicks</th>
                  <th className="px-4 py-2 text-right text-sm font-medium text-[#0A1929]/70 dark:text-white/70">Percentage</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                {browsers.map((browser, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <div className="flex items-center gap-2 text-[#0A1929] dark:text-white">
                        <Globe className="w-4 h-4" />
                        {browser.name}
                      </div>
                    </td>
                    <td className="px-4 py-2 text-right text-[#0A1929] dark:text-white">{browser.value}</td>
                    <td className="px-4 py-2 text-right text-[#0A1929] dark:text-white">
                      {((browser.value / analytics.totalClicks) * 100).toFixed(1)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Visual representation */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-gray-50 dark:bg-[#0a213e] p-4 rounded-lg">
            <h3 className="text-sm font-medium text-[#0A1929] dark:text-white mb-3 text-yellow-500">OS Distribution</h3>
            <div className="h-[300px]">
              {renderChart(prepareChartData('os'), 'pie')}
            </div>
          </div>
          <div className="bg-gray-50 dark:bg-[#0a213e] p-4 rounded-lg">
            <h3 className="text-sm font-medium text-[#0A1929] dark:text-white mb-3 text-yellow-500">Browser Distribution</h3>
            <div className="h-[300px]">
              {renderChart(prepareChartData('browsers'), 'pie')}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLocationsTab = () => {
    if (isLoading) {
      return <div className="text-center py-4">Loading location data...</div>;
    }

    if (!analytics || !analytics.locations) {
      return <div className="text-center py-4">No location data available</div>;
    }

    const { countries, cities } = analytics.locations;

    return (
      <div className="space-y-6">
        {/* Countries Section */}
        <div className="bg-gray-50 dark:bg-[#0a213e] p-4 rounded-lg">
          <h3 className="text-sm font-medium text-[#0A1929] dark:text-white mb-3">Countries</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 dark:text-gray-400">Country</th>
                  <th className="px-4 py-2 text-right text-sm font-medium text-gray-500 dark:text-gray-400">Clicks</th>
                  <th className="px-4 py-2 text-right text-sm font-medium text-gray-500 dark:text-gray-400">Percentage</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                {countries.map((country, index) => (
                  <tr key={country.code || index}>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <div className="flex items-center gap-2 text-[#0A1929] dark:text-white">
                        {country.code && (
                          <img 
                            src={`https://flagcdn.com/24x18/${country.code.toLowerCase()}.png`}
                            alt={country.name}
                            className="w-6 h-4 object-cover rounded"
                          />
                        )}
                        {country.name}
                      </div>
                    </td>
                    <td className="px-4 py-2 text-right text-[#0A1929] dark:text-white">{country.value}</td>
                    <td className="px-4 py-2 text-right text-[#0A1929] dark:text-white">
                      {((country.value / analytics.totalClicks) * 100).toFixed(1)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Cities Section */}
        <div className="bg-gray-50 dark:bg-[#0a213e] p-4 rounded-lg">
          <h3 className="text-sm font-medium text-[#0A1929] dark:text-white mb-3">Cities</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 dark:text-gray-400">City</th>
                  <th className="px-4 py-2 text-right text-sm font-medium text-gray-500 dark:text-gray-400">Country</th>
                  <th className="px-4 py-2 text-right text-sm font-medium text-gray-500 dark:text-gray-400">Clicks</th>
                  <th className="px-4 py-2 text-right text-sm font-medium text-gray-500 dark:text-gray-400">Percentage</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                {cities.map((city, index) => (
                  <tr key={city.code || index}>
                    <td className="px-4 py-2 text-[#0A1929] dark:text-white">{city.name}</td>
                    <td className="px-4 py-2 text-right text-[#0A1929] dark:text-white">
                      {city.countryCode && (
                        <div className="flex items-center justify-end gap-2">
                          <img 
                            src={`https://flagcdn.com/24x18/${city.countryCode.toLowerCase()}.png`}
                            alt={city.countryCode}
                            className="w-6 h-4 object-cover rounded"
                          />
                          {city.countryCode}
                        </div>
                      )}
                    </td>
                    <td className="px-4 py-2 text-right text-[#0A1929] dark:text-white">{city.value}</td>
                    <td className="px-4 py-2 text-right text-[#0A1929] dark:text-white">
                      {((city.value / analytics.totalClicks) * 100).toFixed(1)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  const renderTrendsTab = () => {
    if (isLoading) return <div>Loading trends data...</div>;
    if (!analytics) return <div>No analytics data available</div>;

    return (
      <div className="space-y-6">
        {/* Date Range Selector */}
        {renderDateRangeSelector()}

        {/* Click History Chart */}
        <div className="bg-gray-50 dark:bg-[#0a213e] p-4 rounded-lg">
          <h3 className="text-lg font-medium mb-4 text-[#0A1929] dark:text-white">Click History</h3>
          <div className="h-[300px]">
            {renderChart(prepareChartData('clickHistory'), 'line')}
          </div>
          <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
            Showing data from {new Date(analytics.clickHistory[0].date).toLocaleDateString()} 
            to {new Date(analytics.clickHistory[analytics.clickHistory.length - 1].date).toLocaleDateString()}
          </div>
        </div>

        {/* Growth Metrics */}
        <div className="bg-gray-50 dark:bg-[#0a213e] p-4 rounded-lg">
          <h3 className="text-lg font-medium mb-4 text-[#0A1929] dark:text-white">Growth Metrics</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">Weekly Growth</p>
              <p className={`text-2xl font-semibold ${
                analytics.weeklyGrowth >= 0 ? 'text-green-500' : 'text-red-500'
              }`}>
                {analytics.weeklyGrowth > 0 ? '+' : ''}{analytics.weeklyGrowth}%
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">Monthly Growth</p>
              <p className={`text-2xl font-semibold ${
                analytics.monthlyGrowth >= 0 ? 'text-green-500' : 'text-red-500'
              }`}>
                {analytics.monthlyGrowth > 0 ? '+' : ''}{analytics.monthlyGrowth}%
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const exportAnalytics = () => {
    const data = {
      urlInfo: {
        title: urlData.title,
        originalUrl: urlData.originalUrl,
        shortUrl: urlData.shortUrl,
        createdAt: formatDate(urlData.createdAt)
      },
      statistics: {
        totalClicks: analytics.totalClicks,
        humanClicks: analytics.humanClicks,
        browsers: analytics.browser,
        countries: analytics.country,
        operatingSystems: analytics.os
      }
    };

    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `analytics-${urlData.linkId}.json`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const tabs = [
    { id: 'overview', label: 'Overview', icon: Globe },
    { id: 'devices', label: 'Devices', icon: Monitor },
    { id: 'locations', label: 'Locations', icon: MapPin },
    { id: 'trends', label: 'Trends', icon: Activity },
  ];

  const renderDateRangeSelector = () => (
    <div className="flex items-center gap-2 mb-4">
      {['24h', '7d', '30d', 'all'].map((range) => (
        <button
          key={range}
          onClick={() => setDateRange(range)}
          className={`px-3 py-1 rounded-md text-sm transition-colors ${
            dateRange === range
              ? 'bg-primary text-white dark:bg-yellow-500 dark:text-gray-900'
              : 'bg-gray-100 text-gray-600 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600'
          }`}
        >
          {range === '24h' ? 'Last 24h' : 
           range === '7d' ? 'Last 7 days' : 
           range === '30d' ? 'Last 30 days' : 
           'All time'}
        </button>
      ))}
    </div>
  );

  // Add this function to handle QR code download
  const downloadQRCode = () => {
    // Get the SVG element
    const svg = document.querySelector('#url-qr-code');
    if (!svg) return;

    // Create a canvas element
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    // Set canvas size (make it larger for better quality)
    const scale = 4; // Increase this for higher resolution
    canvas.width = 128 * scale;
    canvas.height = 128 * scale;

    // Create an image from the SVG
    const img = new Image();
    const svgData = new XMLSerializer().serializeToString(svg);
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    const svgUrl = URL.createObjectURL(svgBlob);

    img.onload = () => {
      // Fill white background
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      // Draw the QR code
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      
      // Convert to PNG and download
      const pngUrl = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `qr-code-${urlData.linkId}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      
      // Clean up
      URL.revokeObjectURL(svgUrl);
    };

    img.src = svgUrl;
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!analytics) {
    return (
      <div className="p-8 text-center">
        <p className="text-gray-600 dark:text-gray-400">No analytics data available</p>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-[#102B4E] rounded-lg shadow p-4 sm:p-6">
      <div className="mb-4 sm:mb-6">
        <div className="flex justify-between items-start w-full">
          <h2 className="text-lg sm:text-xl font-semibold text-gray-500 dark:text-white/70 break-words pr-2">
            {urlData.title || 'Untitled'}
          </h2>
          <div className="flex gap-2">
            <button
              onClick={exportAnalytics}
              className="p-2 text-gray-500 hover:text-primary dark:text-gray-400 dark:hover:text-yellow-500 rounded-md transition-colors"
              title="Export Analytics"
            >
              <Download size={20} />
            </button>
            <button
              onClick={() => setShowDeleteModal(true)}
              className="p-2 text-gray-500 hover:text-red-500 dark:text-gray-400 dark:hover:text-red-400 rounded-md transition-colors flex-shrink-0"
              title="Delete URL Tracker"
            >
              <Trash2 size={20} className="dark:text-yellow-500" />
            </button>
          </div>
        </div>
        
        <div className="space-y-3 mt-4">
          <div className="flex flex-col gap-3">
            <div className="flex flex-col space-y-2">
              <span className="text-sm text-gray-500 dark:text-white/70">Original URL:</span>
              <div className="flex items-center gap-2 w-full">
                <input
                  type="text"
                  value={urlData.originalUrl}
                  readOnly
                  className="w-full bg-gray-50 dark:bg-[#0a213e] dark:text-white px-3 py-2 text-sm rounded-md overflow-x-auto"
                />
              </div>
            </div>
            
            <div className="flex flex-col space-y-2">
              <span className="text-sm text-gray-500 dark:text-white/70">Short URL:</span>
              <div className="flex items-center gap-2 w-full">
                <input
                  type="text"
                  value={urlData.shortUrl}
                  readOnly
                  className="w-full bg-gray-50 dark:bg-[#0a213e] dark:text-white px-3 py-2 text-sm rounded-md"
                />
                <button
                  onClick={() => copyToClipboard(urlData.shortUrl)}
                  className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-yellow-500 rounded-md flex-shrink-0"
                >
                  {copied ? <Check size={20} /> : <Copy size={20} />}
                </button>
              </div>
            </div>

            {/* UTM Parameters Section */}
            <div className="flex flex-col space-y-2 mt-4">
              <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">UTM Parameters</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <span className="text-sm text-gray-500 dark:text-white/70">Source:</span>
                  <div className="bg-gray-50 dark:bg-[#0a213e] px-3 py-2 rounded-md text-sm">
                    {urlData.utmSource || '-'}
                  </div>
                </div>
                <div>
                  <span className="text-sm text-gray-500 dark:text-white/70">Medium:</span>
                  <div className="bg-gray-50 dark:bg-[#0a213e] px-3 py-2 rounded-md text-sm">
                    {urlData.utmMedium || '-'}
                  </div>
                </div>
                <div>
                  <span className="text-sm text-gray-500 dark:text-white/70">Campaign:</span>
                  <div className="bg-gray-50 dark:bg-[#0a213e] px-3 py-2 rounded-md text-sm">
                    {urlData.utmCampaign || '-'}
                  </div>
                </div>
                <div>
                  <span className="text-sm text-gray-500 dark:text-white/70">Content:</span>
                  <div className="bg-gray-50 dark:bg-[#0a213e] px-3 py-2 rounded-md text-sm">
                    {urlData.utmContent || '-'}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="text-sm text-gray-500 dark:text-white/70 mt-2">
            Created on: {formatDate(urlData.createdAt)}
          </div>
        </div>
      </div>

      <div className="border-b border-gray-200 dark:border-gray-700 mb-4 sm:mb-6">
        <nav className="flex overflow-x-auto hide-scrollbar">
          <div className="grid grid-cols-4 w-full">
            {tabs.map(tab => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`flex items-center justify-center gap-2 py-2 border-b-2 ${
                  activeTab === tab.id
                  ? 'border-primary dark:border-yellow-500 text-primary dark:text-yellow-500'
                  : 'border-transparent text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300'
                }`}
              >
                <tab.icon size={20} />
                <span className="hidden sm:inline">{tab.label}</span>
              </button>
            ))}
          </div>
        </nav>
      </div>

      <div className="mt-6">
        {activeTab === 'overview' && renderOverviewTab()}
        {activeTab === 'devices' && renderDevicesTab()}
        {activeTab === 'locations' && renderLocationsTab()}
        {activeTab === 'trends' && renderTrendsTab()}
      </div>

      <DeleteConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        title={urlData.title}
      />

      <div className="flex items-center gap-2 mb-4">
        <button
          onClick={() => setIsRealTimeEnabled(!isRealTimeEnabled)}
          className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none mt-4 ${
            isRealTimeEnabled 
              ? 'bg-primary dark:bg-yellow-500' 
              : 'bg-gray-200 dark:bg-gray-700'
          }`}
        >
          <span className="sr-only">Enable real-time updates</span>
          <span
            className={`${
              isRealTimeEnabled ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </button>
        <span className="text-sm text-gray-600 dark:text-gray-300">Real-time updates</span>
        {isRealTimeEnabled && (
          <span className="flex h-2 w-2">
            <span className="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-green-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
          </span>
        )}
      </div>
    </div>
  );
};

export default AnalyticsDashboard;
