import { 
  collection, 
  doc, 
  getDoc, 
  getDocs, 
  query, 
  orderBy, 
  deleteDoc, 
  updateDoc,
  setDoc,
  serverTimestamp,
  increment,
  where
} from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { getLinkAnalytics } from '../../url-analytics/utils/shortApi';

export const getMailtoLinks = async (userId) => {
  try {
    const mailtoLinksRef = collection(db, `users/${userId}/mailtoLinks`);
    const q = query(mailtoLinksRef, orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    
    const links = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      analytics: null
    }));

    // Fetch analytics for each link with shortUrl
    const linksWithAnalytics = await Promise.all(
      links.map(async (link) => {
        if (link.linkId) {
          try {
            const rawAnalytics = await getLinkAnalytics(link.linkId);
            // Process analytics data similar to AnalyticsDashboard
            const processedAnalytics = {
              totalClicks: rawAnalytics.totalClicks || 0,
              humanClicks: rawAnalytics.humanClicks || 0,
              clicksChange: rawAnalytics.totalClicksChange || "0",
              clickStatistics: rawAnalytics.clickStatistics || null
            };
            
            return {
              ...link,
              analytics: processedAnalytics
            };
          } catch (error) {
            console.error(`Failed to fetch analytics for link ${link.id}:`, error);
            return link;
          }
        }
        return link;
      })
    );
    
    return linksWithAnalytics;
  } catch (error) {
    console.error('Error fetching mailto links:', error);
    throw error;
  }
};

export const deleteMailtoLink = async (userId, linkId) => {
  try {
    const linkRef = doc(db, 'users', userId, 'mailtoLinks', linkId);
    await deleteDoc(linkRef);
  } catch (error) {
    console.error('Error deleting mailto link:', error);
    throw error;
  }
};

export const resetMailtoLinksCount = async (userId) => {
  try {
    const mailtoLinksRef = collection(db, 'users', userId, 'mailtoLinks');
    const querySnapshot = await getDocs(mailtoLinksRef);
    const actualCount = querySnapshot.size;

    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      mailtoLinksGenerated: actualCount
    });
    
    console.log(`Reset count to ${actualCount} for user ${userId}`);
    return actualCount;
  } catch (error) {
    console.error('Error resetting mailto links count:', error);
    throw error;
  }
};

export const createUserDocument = async (user) => {
  if (!user) return;

  const userRef = doc(db, 'users', user.uid);
  const docSnap = await getDoc(userRef);

  if (!docSnap.exists()) {
    try {
      await setDoc(userRef, {
        email: user.email,
        displayName: user.displayName,
        createdAt: serverTimestamp(),
        lastLogin: serverTimestamp(),
        mailtoLinksGenerated: 0
      });
    } catch (error) {
      console.error('Error creating user document:', error);
    }
  }
};

export const updateUserLastLogin = async (userId) => {
  if (!userId) return;
  
  try {
    const userRef = doc(db, 'users', userId);
    
    console.log('Updating lastLogin for user:', userId);
    
    await updateDoc(userRef, {
      lastLogin: serverTimestamp()
    });
    
    const updatedDoc = await getDoc(userRef);
    console.log('Updated lastLogin:', updatedDoc.data()?.lastLogin);
    
  } catch (error) {
    console.error('Error updating last login:', error);
  }
};

export const createMailtoLink = async (userId, linkData) => {
  try {
    const mailtoLinksRef = collection(db, `users/${userId}/mailtoLinks`);
    const newLinkRef = doc(mailtoLinksRef);
    
    await setDoc(newLinkRef, {
      ...linkData,
      createdAt: serverTimestamp()
    });

    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      mailtoLinksGenerated: increment(1)
    });

    return newLinkRef.id;
  } catch (error) {
    console.error('Error creating mailto link:', error);
    throw error;
  }
};

export const getTrackedUrls = async (userId) => {
  try {
    const urlsRef = collection(db, 'trackedUrls');
    const q = query(
      urlsRef, 
      where('userId', '==', userId),
      orderBy('createdAt', 'desc')
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error fetching tracked URLs:', error);
    throw error;
  }
};

const storeEmailContent = async (emailData) => {
  const contentRef = doc(collection(db, 'emailContent'));
  await setDoc(contentRef, {
    ...emailData,
    createdAt: serverTimestamp(),
    expiresAt: new Date(Date.now() + 24 * 60 * 60 * 1000) // 24 hours from now
  });
  return contentRef.id;
};

export { storeEmailContent };
