import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Globe, Mail, ChevronRight, BarChart,
  BarChart2
} from 'lucide-react';

const Card = ({ children, onClick, className }) => (
  <div
    onClick={onClick}
    className={`bg-white dark:bg-[#102B4E] rounded-lg shadow ${className}`}
  >
    {children}
  </div>
);

const DashboardCard = ({ title, description, icon: Icon, link, index, onClick }) => (
  <Card
    className={`transition-all duration-300 hover:shadow-lg hover:-translate-y-1 ${
      index >= 5 ? 'opacity-50 pointer-events-none' : 'cursor-pointer'
    }`}
    onClick={onClick}
  >
    <div className="p-6 flex flex-col items-center justify-center">
      <Icon size={40} className="text-primary dark:text-yellow-500" />
      <h3 className="mt-4 text-xl font-semibold text-primary dark:text-yellow-500">
        {title}
      </h3>
      <p className="mt-2 text-sm text-gray-600 dark:text-yellow-400 text-center">
        {description}
      </p>
      <span className="mt-4 text-primary dark:text-yellow-500">
        <ChevronRight size={20} />
      </span>
    </div>
  </Card>
);

const Dashboard = () => {
  const navigate = useNavigate();

  const projects = [
    { 
      title: "Word of Bees", 
      description: "Amplify Palestinian voices and debunk misinformation", 
      icon: Globe, 
      link: "https://wob-backend-qwtbrr3rxa-ew.a.run.app/auth/login",
      isExternal: true
    },
    { 
      title: "Mailto Generator", 
      description: "Create mailto links with custom parameters", 
      icon: Mail, 
      link: "/mailto",
      isExternal: false
    },
    { 
      title: "URL Analytics", 
      description: "Track and analyze URL performance with detailed metrics", 
      icon: BarChart, 
      link: "/url-analytics",
      isExternal: false
    },
    { 
      title: "Telegram Polls", 
      description: "Create and analyze Telegram polls with real-time analytics", 
      icon: BarChart2, 
      link: "/polls",
      isExternal: false
    }
  ];

  const handleCardClick = (project) => {
    if (project.isExternal) {
      window.location.href = project.link;
    } else {
      navigate(project.link);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {projects.map((project, index) => (
          <DashboardCard
            key={project.title}
            {...project}
            index={index}
            onClick={() => handleCardClick(project)}
          />
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
