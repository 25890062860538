import React, { useState } from 'react';
import { X, AlertTriangle } from 'lucide-react';
import { deletePoll } from '../services/firestore';
import { toast } from 'react-toastify';

const DeletePollModal = ({ isOpen, onClose, pollId, pollQuestion, onDeleteSuccess }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deletePoll(pollId);
      toast.success('Poll deleted successfully');
      onClose();
      if (onDeleteSuccess) {
        onDeleteSuccess();
      }
    } catch (error) {
      toast.error('Failed to delete poll');
      console.error('Error deleting poll:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-darkbg rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2 text-red-600 dark:text-red-400">
            <AlertTriangle size={24} />
            <h3 className="text-lg font-semibold">Delete Poll</h3>
          </div>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 
                     dark:hover:text-gray-200"
          >
            <X size={20} />
          </button>
        </div>

        <div className="space-y-4">
          <p className="text-gray-700 dark:text-gray-300">
            Are you sure you want to delete this poll? This action cannot be undone.
          </p>

          <div className="bg-gray-50 dark:bg-gray-800 rounded-md p-4">
            <p className="text-gray-800 dark:text-gray-200 font-medium">
              {pollQuestion}
            </p>
          </div>

          <div className="flex justify-end gap-4 mt-6">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 
                       dark:hover:bg-gray-700 rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              disabled={isDeleting}
              className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 
                       disabled:opacity-50"
            >
              {isDeleting ? 'Deleting...' : 'Delete Poll'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePollModal;
