import { 
  collection, 
  addDoc, 
  serverTimestamp, 
  query, 
  where, 
  orderBy, 
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  updateDoc
} from 'firebase/firestore';
import { db, auth } from '../../../config/firebase';

export const createPoll = async (pollData) => {
  let docRef;
  try {
    console.log('Starting poll creation process:', pollData);

    // First create the poll document in Firestore
    docRef = await addDoc(collection(db, 'polls'), {
      ...pollData,
      status: 'pending',
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      createdBy: auth.currentUser?.uid
    });

    console.log('Firestore document created:', docRef.id);

    // Make direct HTTP call to Cloud Function instead of using httpsCallable
    const response = await fetch('https://us-central1-arabees.cloudfunctions.net/createTelegramPoll', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        question: pollData.question,
        options: pollData.options
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    
    await updateDoc(doc(db, 'polls', docRef.id), {
      status: 'active',
      telegramData: result.data,
      updatedAt: serverTimestamp()
    });

    return docRef.id;
  } catch (error) {
    console.error('Error in createPoll:', error);
    if (docRef?.id) {
      await updateDoc(doc(db, 'polls', docRef.id), {
        status: 'failed',
        error: error.message,
        updatedAt: serverTimestamp()
      });
    }
    throw error;
  }
};

export const getUserPolls = async (userId) => {
  try {
    const q = query(
      collection(db, 'polls'),
      where('createdBy', '==', userId),
      orderBy('createdAt', 'desc')
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error fetching user polls:', error);
    throw error;
  }
};

export const getPollAnalytics = async (pollId) => {
  console.log('Getting analytics for poll:', pollId);
  
  if (!pollId) {
    throw new Error('Poll ID is required');
  }

  try {
    console.log('Fetching poll document...');
    const pollDoc = await getDoc(doc(db, 'polls', pollId));
    
    if (!pollDoc.exists()) {
      console.log('Poll not found');
      throw new Error('Poll not found');
    }
    
    console.log('Poll document found:', pollDoc.data());

    // Get all votes for this poll
    console.log('Fetching votes...');
    console.log('Vote query parameters:', {
      pollId,
      queryPath: `pollVotes where pollId == ${pollId} orderBy timestamp asc`
    });
    const votesQuery = query(
      collection(db, 'pollVotes'),
      where('pollId', '==', pollId),
      orderBy('timestamp', 'asc')
    );
    
    try {
      const votesSnapshot = await getDocs(votesQuery);
      console.log('Votes found:', votesSnapshot.size);
      
      const votes = votesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log('Processed votes:', votes);

      // Process votes into trends data
      console.log('Processing analytics...');
      const votingTrends = processVotingTrends(votes);
      const optionVotes = processOptionVotes(votes, pollDoc.data().options.length);
      
      console.log('Analytics processed:', {
        votingTrends,
        optionVotes,
        totalVotes: votes.length
      });

      return {
        id: pollDoc.id,
        ...pollDoc.data(),
        analytics: {
          votingTrends,
          optionVotes,
          totalVotes: votes.length
        }
      };
    } catch (indexError) {
      console.warn('Analytics index error:', indexError);
      return {
        id: pollDoc.id,
        ...pollDoc.data(),
        analytics: {
          votingTrends: [],
          optionVotes: {},
          totalVotes: 0
        }
      };
    }
  } catch (error) {
    console.error('Error in getPollAnalytics:', error);
    throw error;
  }
};

export const deletePoll = async (pollId) => {
  try {
    const user = auth.currentUser;
    
    console.log('Current user:', user?.uid);
    
    if (!user) {
      throw new Error('User must be authenticated to delete polls');
    }

    // First verify the user owns this poll
    const pollDoc = await getDoc(doc(db, 'polls', pollId));
    if (!pollDoc.exists()) {
      throw new Error('Poll not found');
    }

    const pollData = pollDoc.data();
    console.log('Poll createdBy:', pollData.createdBy);
    console.log('User ID:', user.uid);

    if (pollData.createdBy !== user.uid) {
      throw new Error('Unauthorized to delete this poll');
    }

    // Delete the poll first
    await deleteDoc(doc(db, 'polls', pollId));
    console.log('Poll deleted successfully');

    // Note: We might need to remove this part as it might be causing permission issues
    // Consider moving vote deletion to a Cloud Function instead
    /*
    const votesQuery = query(
      collection(db, 'pollVotes'),
      where('pollId', '==', pollId)
    );
    
    const votesSnapshot = await getDocs(votesQuery);
    const deletePromises = votesSnapshot.docs.map(doc => deleteDoc(doc.ref));
    await Promise.all(deletePromises);
    */
  } catch (error) {
    console.error('Error deleting poll:', error);
    throw error;
  }
};

export const updatePollStatus = async (pollId, status) => {
  try {
    await updateDoc(doc(db, 'polls', pollId), {
      status: status
    });
  } catch (error) {
    console.error('Error updating poll status:', error);
    throw error;
  }
};

export const updatePollWithTelegramData = async (pollId, telegramData) => {
  // Update the poll document with telegram data
  return await updateDoc(doc(db, 'polls', pollId), { telegram: telegramData });
};

// Helper function to process voting trends
const processVotingTrends = (votes) => {
  const trends = [];
  let cumulativeVotes = 0;

  votes.forEach(vote => {
    cumulativeVotes++;
    trends.push({
      timestamp: vote.timestamp.toMillis(),
      votes: cumulativeVotes
    });
  });

  return trends;
};

// Helper function to process option votes
const processOptionVotes = (votes, numOptions) => {
  const optionVotes = {};
  
  // Initialize all options with 0 votes
  for (let i = 1; i <= numOptions; i++) {
    optionVotes[`option${i}`] = 0;
  }

  // Count votes for each option
  votes.forEach(vote => {
    if (Array.isArray(vote.options)) {
      // Handle multiple answers
      vote.options.forEach(option => {
        optionVotes[`option${option + 1}`]++;
      });
    } else {
      // Handle single answer
      optionVotes[`option${vote.option + 1}`]++;
    }
  });

  return optionVotes;
};
