import React from 'react';
import { Info } from 'lucide-react';

const UTMField = ({ 
  label, 
  name, 
  value, 
  onChange, 
  options, 
  info,
  placeholder 
}) => {
  return (
    <div>
      <div className="flex items-center gap-2 mb-1">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {label}
        </label>
        <div className="group relative">
          <Info size={16} className="text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-400" />
          <div className="invisible group-hover:visible absolute left-1/2 -translate-x-1/2 bottom-full mb-2 px-3 py-2 bg-gray-900 text-white text-sm rounded-lg whitespace-nowrap z-10">
            {info}
          </div>
        </div>
      </div>
      <select
        name={name}
        value={value}
        onChange={onChange}
        className="w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300"
      >
        <option value="">{placeholder}</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default UTMField;
