import React, { useState } from 'react';
import { Copy, Check, ExternalLink } from 'lucide-react';
import { useAuth } from '../../../features/auth/contexts/AuthContext';
import { db } from '../../../config/firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { nanoid } from 'nanoid';
import { SHORTIO_CONFIG } from '../../../config/shortio';
import UTMField from './UTMField';
import { UTM_OPTIONS, UTM_INFO } from '../constants/utmOptions';

const URLTrackerModal = ({ isOpen, onClose, onUrlAdded }) => {
  const [formData, setFormData] = useState({
    longUrl: '',
    title: '',
    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
    utmContent: '',
    utmTerm: '',
    createShortUrl: true
  });
  const [copied, setCopied] = useState(false);
  const [shortUrl, setShortUrl] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useAuth();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'longUrl') {
      let processedUrl = value.trim();
      processedUrl = processedUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');
      setFormData(prev => ({
        ...prev,
        [name]: processedUrl
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const buildUrlWithUtm = () => {
    if (!formData.longUrl) {
      return '';
    }

    try {
      let urlToProcess = formData.longUrl;
      if (!urlToProcess.startsWith('http://') && !urlToProcess.startsWith('https://')) {
        urlToProcess = `https://${urlToProcess}`;
      }

      const url = new URL(urlToProcess);
      
      if (formData.utmSource) url.searchParams.set('utm_source', formData.utmSource);
      if (formData.utmMedium) url.searchParams.set('utm_medium', formData.utmMedium);
      if (formData.utmCampaign) url.searchParams.set('utm_campaign', formData.utmCampaign);
      if (formData.utmContent) url.searchParams.set('utm_content', formData.utmContent);
      if (formData.utmTerm) url.searchParams.set('utm_term', formData.utmTerm);

      return url.toString();
    } catch (error) {
      console.error('Error building URL:', error);
      return formData.longUrl;
    }
  };

  const shortenUrl = async (longUrl, title = '') => {
    try {
      const data = {
        domain: SHORTIO_CONFIG.DOMAIN,
        originalURL: longUrl,
        title: title,
        allowDuplicates: false
      };

      const response = await fetch('https://api.short.io/links/public', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'authorization': SHORTIO_CONFIG.API_KEY
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create short URL');
      }

      const responseData = await response.json();
      return {
        shortUrl: responseData.shortURL,
        linkId: responseData.idString
      };
    } catch (error) {
      console.error('Error shortening URL:', error);
      throw error;
    }
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const validateUrl = (url) => {
    if (!url) return '';
    
    try {
      const urlWithProtocol = url.match(/^https?:\/\//) ? url : `https://${url}`;
      const urlObject = new URL(urlWithProtocol);
      return urlObject.toString();
    } catch (error) {
      console.error('URL validation error:', error);
      return '';
    }
  };

  const UrlPreview = ({ url, isShort = false }) => {
    if (!url) return null;
    
    const validUrl = validateUrl(url);
    
    return (
      <div className="flex items-center gap-2 mb-2">
        <input
          type="text"
          readOnly
          value={validUrl}
          className="w-full rounded-md border border-gray-300 px-3 py-2 bg-gray-50 dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300"
        />
        <button
          type="button"
          onClick={() => copyToClipboard(validUrl)}
          className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-yellow-500"
          title="Copy to clipboard"
        >
          {copied ? <Check size={20} /> : <Copy size={20} />}
        </button>
        <a
          href={validUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-yellow-500"
          title="Open in new tab"
        >
          <ExternalLink size={20} />
        </a>
      </div>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const urlWithUtm = buildUrlWithUtm();
      const shortUrlData = await shortenUrl(urlWithUtm, formData.title);

      const urlDoc = {
        originalUrl: formData.longUrl,
        shortUrl: shortUrlData.shortUrl,
        linkId: shortUrlData.linkId,
        title: formData.title || 'Untitled Link',
        userId: user.uid,
        createdAt: serverTimestamp()
      };

      const docRef = await addDoc(collection(db, 'trackedUrls'), urlDoc);
      
      if (onUrlAdded) {
        onUrlAdded({ id: docRef.id, ...urlDoc });
      }
      
      onClose();
    } catch (error) {
      console.error('Error saving URL:', error);
      alert(error.message || 'Failed to save URL. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const ConfigurationWarning = () => {
    if (!SHORTIO_CONFIG.API_KEY) {
      return (
        <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-lg mb-4">
          <p className="text-yellow-800 dark:text-yellow-200 text-sm">
            URL shortening is currently disabled due to missing configuration. 
            Links will be tracked without short URLs.
          </p>
        </div>
      );
    }
    return null;
  };

  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ${isOpen ? '' : 'hidden'}`}>
      <div className="bg-white dark:bg-[#102B4E] rounded-lg shadow-xl max-w-4xl w-full mx-4 max-h-[90vh] overflow-y-auto">
        <ConfigurationWarning />
        <form onSubmit={handleSubmit}>
          <div className="p-6 space-y-6">
            <div className="bg-white dark:bg-[#102B4E] p-6 rounded-lg shadow space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  URL to Track <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="longUrl"
                  value={formData.longUrl}
                  onChange={handleInputChange}
                  placeholder="example.com/page"
                  required
                  className="w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300"
                />
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  Enter the URL without http:// or https:// - it will be added automatically
                </p>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  URL Title
                </label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  className="w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300"
                  placeholder="My Campaign Link"
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <UTMField
                  label="UTM Source"
                  name="utmSource"
                  value={formData.utmSource}
                  onChange={handleInputChange}
                  options={UTM_OPTIONS.source}
                  info={UTM_INFO.source}
                  placeholder="Select traffic source"
                />

                <UTMField
                  label="UTM Medium"
                  name="utmMedium"
                  value={formData.utmMedium}
                  onChange={handleInputChange}
                  options={UTM_OPTIONS.medium}
                  info={UTM_INFO.medium}
                  placeholder="Select marketing medium"
                />

                <UTMField
                  label="UTM Campaign"
                  name="utmCampaign"
                  value={formData.utmCampaign}
                  onChange={handleInputChange}
                  options={UTM_OPTIONS.campaign}
                  info={UTM_INFO.campaign}
                  placeholder="Select campaign name"
                />

                <UTMField
                  label="UTM Content"
                  name="utmContent"
                  value={formData.utmContent}
                  onChange={handleInputChange}
                  options={UTM_OPTIONS.content}
                  info={UTM_INFO.content}
                  placeholder="Select content identifier"
                />
              </div>
            </div>

            <div className="bg-white dark:bg-[#102B4E] p-6 rounded-lg shadow space-y-4">
              {formData.longUrl && (
                <>
                  <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300 mb-2">
                    URL with UTM Parameters
                  </h3>
                  <UrlPreview url={buildUrlWithUtm()} />
                </>
              )}
              
              {shortUrl && (
                <>
                  <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Short URL
                  </h3>
                  <UrlPreview url={shortUrl} isShort={true} />
                </>
              )}
            </div>
          </div>

          <div className="flex justify-end gap-2 p-4 border-t dark:border-gray-700">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-100"
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark disabled:opacity-50 dark:bg-yellow-500 dark:hover:bg-yellow-600"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default URLTrackerModal;
