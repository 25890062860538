export const UTM_OPTIONS = {
  source: [
    { value: 'telegram', label: 'Telegram' },
    { value: 'twitter', label: 'Twitter' },
    { value: 'facebook', label: 'Facebook' },
    { value: 'instagram', label: 'Instagram' },
    { value: 'tiktok', label: 'TikTok' },
    { value: 'linkedin', label: 'LinkedIn' },
    { value: 'email', label: 'Email' },
    { value: 'newsletter', label: 'Newsletter' },
  ],
  medium: [
    { value: 'social_post', label: 'Social Media Post' },
    { value: 'social_story', label: 'Social Media Story' },
    { value: 'social_bio', label: 'Social Media Bio' },
    { value: 'email', label: 'Email' },
    { value: 'newsletter', label: 'Newsletter' },
    { value: 'direct', label: 'Direct Message' },
  ],
  campaign: [
    { value: 'amplify_voices', label: 'Amplify Palestinian Voices' },
    { value: 'fact_check', label: 'Fact Checking' },
    { value: 'debunk_misinfo', label: 'Debunk Misinformation' },
    { value: 'share_stories', label: 'Share Palestinian Stories' },
    { value: 'raise_awareness', label: 'Raise Awareness' },
  ],
  content: [
    { value: 'post', label: 'Social Media Post' },
    { value: 'story', label: 'Social Media Story' },
    { value: 'bio_link', label: 'Profile/Bio Link' },
    { value: 'newsletter', label: 'Newsletter Link' },
    { value: 'thread', label: 'Thread/Reply' },
    { value: 'dm', label: 'Direct Message' },
  ],
};

export const UTM_INFO = {
  source: "Identifies which platform sent the traffic (e.g., Telegram, Twitter)",
  medium: "The type of content used (e.g., social post, story, email)",
  campaign: "The specific awareness campaign or initiative",
  content: "Used to track different content formats or placements",
};
