import React, { useState, useEffect, useCallback } from 'react';
import { Plus, ExternalLink, BarChart2, Copy, Check } from 'lucide-react';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { useAuth } from '../../../features/auth/contexts/AuthContext';
import URLTrackerModal from '../components/URLTrackerModal';
import { useNavigate } from 'react-router-dom';
import { getLinkAnalytics } from '../utils/shortApi';

const URLAnalyticsDashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [trackedUrls, setTrackedUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(null);

  const loadUrls = useCallback(async () => {
    if (!user) {
      setError('User not authenticated');
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const urlsRef = collection(db, 'trackedUrls');
      const q = query(
        urlsRef,
        where('userId', '==', user.uid),
        orderBy('createdAt', 'desc')
      );
      
      const querySnapshot = await getDocs(q);
      const urls = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        analytics: null
      }));

      // Fetch analytics for each URL
      const urlsWithAnalytics = await Promise.all(
        urls.map(async (url) => {
          try {
            const analytics = await getLinkAnalytics(url.linkId);
            return {
              ...url,
              analytics
            };
          } catch (error) {
            console.error(`Failed to fetch analytics for URL ${url.id}:`, error);
            return url;
          }
        })
      );
      
      setTrackedUrls(urlsWithAnalytics);
    } catch (error) {
      console.error('Error loading URLs:', error);
      setError('Failed to load URLs. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    loadUrls();
    // Set up polling for real-time updates
    const interval = setInterval(loadUrls, 300000); // 5 minutes
    return () => clearInterval(interval);
  }, [loadUrls]);

  const handleViewAnalytics = (urlId) => {
    navigate(`/analytics/${urlId}`);
  };

  const copyToClipboard = async (text, urlId) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(urlId);
      setTimeout(() => setCopied(null), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const URLCard = ({ url }) => (
    <div className="bg-white dark:bg-[#102B4E] rounded-lg shadow-md p-6 flex flex-col">
      <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-2 truncate">
        {url.title || 'Untitled'}
      </h3>
      
      <div className="flex items-center gap-2 mb-4">
        <span className="text-sm text-gray-500 dark:text-gray-400 truncate">
          {url.shortUrl}
        </span>
        <div className="flex items-center gap-1">
          <a 
            href={url.shortUrl} 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-primary dark:text-yellow-500 hover:opacity-80 p-1"
          >
            <ExternalLink size={16} />
          </a>
          <button
            onClick={() => copyToClipboard(url.shortUrl, url.id)}
            className="text-primary dark:text-yellow-500 hover:opacity-80 p-1"
          >
            {copied === url.id ? <Check size={16} /> : <Copy size={16} />}
          </button>
        </div>
      </div>

      <div className="flex items-center justify-between mt-auto">
        <div className="text-gray-600 dark:text-gray-300">
          <span className="text-2xl font-bold">{url.analytics?.totalClicks || 0}</span>
          <span className="text-sm ml-2">clicks</span>
        </div>
        
        <button
          onClick={() => handleViewAnalytics(url.id)}
          className="flex items-center gap-2 px-4 py-2 bg-primary/10 text-primary dark:bg-yellow-500/10 dark:text-yellow-500 rounded-md hover:opacity-80"
        >
          <BarChart2 size={16} />
          Analytics
        </button>
      </div>
    </div>
  );

  if (error) {
    return (
      <div className="max-w-7xl mx-auto p-6">
        <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-lg p-4">
          <p className="text-red-600 dark:text-red-400">{error}</p>
          <button
            onClick={loadUrls}
            className="mt-2 text-red-600 dark:text-red-400 hover:text-red-800 dark:hover:text-red-300"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-primary dark:text-yellow-500">
          URL Analytics
        </h1>
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex items-center gap-2 px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark dark:bg-yellow-500 dark:hover:bg-yellow-600 dark:text-[#0A1929]"
        >
          <Plus size={20} />
          New URL Tracker
        </button>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center py-12">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary dark:border-yellow-500"></div>
        </div>
      ) : trackedUrls.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-gray-500 dark:text-gray-400">
            No tracked URLs yet. Create one to get started!
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {trackedUrls.map(url => (
            <URLCard key={url.id} url={url} />
          ))}
        </div>
      )}

      <URLTrackerModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onUrlAdded={loadUrls}
      />
    </div>
  );
};

export default URLAnalyticsDashboard;
