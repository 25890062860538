import React from 'react';
import { Link } from 'react-router-dom';
import UserMenu from './UserMenu';

const Navbar = ({ user, onLogout, darkMode, onToggleDarkMode }) => {
  return (
    <header className="bg-white dark:bg-[#102B4E] shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex-1 flex justify-center">
            <Link to="/" className="flex items-center">
              <img 
                src={`/images/${darkMode ? 'logo-dark.png' : 'logo-light.png'}`}
                alt="Logo" 
                className="h-16 w-auto hover:opacity-80 transition-opacity duration-200"
              />
            </Link>
          </div>
          <UserMenu 
            user={user}
            onLogout={onLogout}
            darkMode={darkMode}
            onToggleDarkMode={onToggleDarkMode}
          />
        </div>
      </div>
    </header>
  );
};

export default Navbar;
