import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../auth/contexts/AuthContext';
import { getMailtoLinks, deleteMailtoLink } from '../../auth/services/firestore';
import { Plus } from 'lucide-react';
import MailtoGenerator from '../components/MailtoGenerator';
import MailtoCard from '../components/MailtoCard';
import { collection, query, orderBy, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { getLinkAnalytics, shortenUrl } from '../../url-analytics/utils/shortApi';

const MailtoDashboard = () => {
  const [links, setLinks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const loadLinks = useCallback(async () => {
    if (!user) {
      setError('User not authenticated');
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);
      const mailtoLinksRef = collection(db, `users/${user.uid}/mailtoLinks`);
      const q = query(mailtoLinksRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      
      const links = querySnapshot.docs.map(doc => {
        const data = doc.data();
        const mailtoUrl = `mailto:${data.to}${data.cc ? `?cc=${encodeURIComponent(data.cc)}` : ''}${data.bcc ? `${data.cc ? '&' : '?'}bcc=${encodeURIComponent(data.bcc)}` : ''}${data.subject ? `${data.cc || data.bcc ? '&' : '?'}subject=${encodeURIComponent(data.subject)}` : ''}${data.body ? `&body=${encodeURIComponent(data.body)}` : ''}`;
        return {
          id: doc.id,
          ...data,
          mailtoUrl,
          analytics: null
        };
      });

      const linksWithShortUrls = await Promise.all(
        links.map(async (link) => {
          if (!link.shortUrl || !link.linkId) {
            try {
              const shortUrlData = await shortenUrl(link.mailtoUrl, link.subject || 'Mailto Link');
              await updateDoc(doc(db, `users/${user.uid}/mailtoLinks`, link.id), {
                shortUrl: shortUrlData.shortUrl,
                linkId: shortUrlData.linkId
              });
              return {
                ...link,
                shortUrl: shortUrlData.shortUrl,
                linkId: shortUrlData.linkId
              };
            } catch (error) {
              return link;
            }
          }
          return link;
        })
      );

      const linksWithAnalytics = await Promise.all(
        linksWithShortUrls.map(async (link) => {
          if (link.linkId) {
            try {
              const analytics = await getLinkAnalytics(link.linkId);
              return {
                ...link,
                analytics
              };
            } catch (error) {
              // Error silently handled
            }
          }
          return {
            ...link,
            analytics: {
              totalClicks: 0,
              humanClicks: 0,
              totalClicksChange: "0",
              clickStatistics: null
            }
          };
        })
      );

      setLinks(linksWithAnalytics);
    } catch (error) {
      setError('Failed to load links. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  const handleDeleteLink = async (linkId) => {
    try {
      await deleteMailtoLink(user.uid, linkId);
      await loadLinks(); // Reload the links after deletion
    } catch (error) {
      throw error; // Re-throw to handle in the component
    }
  };

  useEffect(() => {
    loadLinks();
    // Set up polling for real-time updates
    const interval = setInterval(loadLinks, 30000); // 30 seconds
    return () => clearInterval(interval);
  }, [loadLinks]);

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-primary dark:text-yellow-500">
          Mailto Links
        </h1>
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex items-center gap-2 px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark"
        >
          <Plus size={20} />
          New Link
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {links.map(link => (
          <MailtoCard 
            key={link.id} 
            link={link} 
            onDelete={handleDeleteLink}
          />
        ))}
      </div>

      <MailtoGenerator
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={loadLinks}
      />
    </div>
  );
};

export default MailtoDashboard;
