import React, { useState, useEffect } from 'react';
import { Plus, Search, Filter, Loader } from 'lucide-react';
import { useAuth } from '../../auth/contexts/AuthContext';
import { getUserPolls } from '../services/firestore';
import PollCard from './PollCard';
import PollCreator from './PollCreator';

const PollDashboard = () => {
  const { user } = useAuth();
  const [polls, setPolls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    fetchPolls();
  }, [user]);

  const fetchPolls = async () => {
    try {
      const userPolls = await getUserPolls(user.uid);
      setPolls(userPolls);
    } catch (error) {
      console.error('Error fetching polls:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredPolls = polls
    .filter(poll => {
      const matchesSearch = poll.question.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesStatus = filterStatus === 'all' || poll.status === filterStatus;
      return matchesSearch && matchesStatus;
    })
    .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

  const EmptyState = () => (
    <div className="text-center py-12">
      <div className="inline-flex items-center justify-center w-16 h-16 rounded-full 
                    bg-gray-100 dark:bg-gray-800 mb-4">
        <Filter className="w-8 h-8 text-gray-400 dark:text-gray-500" />
      </div>
      <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
        No polls found
      </h3>
      <p className="text-gray-500 dark:text-gray-400 mb-4">
        {searchTerm || filterStatus !== 'all' 
          ? "Try adjusting your filters or search terms"
          : "Create your first poll to get started"}
      </p>
      <button
        onClick={() => setShowCreateModal(true)}
        className="inline-flex items-center gap-2 px-4 py-2 bg-primary dark:bg-yellow-500 
                 text-white dark:text-gray-900 rounded-md hover:bg-primary-dark 
                 dark:hover:bg-yellow-400"
      >
        <Plus size={20} />
        Create Poll
      </button>
    </div>
  );

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
          Polls Dashboard
        </h1>
        <button
          onClick={() => setShowCreateModal(true)}
          className="inline-flex items-center gap-2 px-4 py-2 bg-primary dark:bg-yellow-500 
                   text-white dark:text-gray-900 rounded-md hover:bg-primary-dark 
                   dark:hover:bg-yellow-400"
        >
          <Plus size={20} />
          Create Poll
        </button>
      </div>

      {/* Filters */}
      <div className="flex flex-col sm:flex-row gap-4 mb-6">
        <div className="flex-1">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 
                           text-gray-400 dark:text-gray-500" size={20} />
            <input
              type="text"
              placeholder="Search polls..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 
                       rounded-md dark:bg-gray-800 dark:text-gray-100"
            />
          </div>
        </div>
        <select
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md 
                   bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
        >
          <option value="all">All Status</option>
          <option value="active">Active</option>
          <option value="closed">Closed</option>
        </select>
      </div>

      {/* Content */}
      {loading ? (
        <div className="flex justify-center items-center py-12">
          <Loader className="w-8 h-8 animate-spin text-primary dark:text-yellow-500" />
        </div>
      ) : filteredPolls.length === 0 ? (
        <EmptyState />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredPolls.map(poll => (
            <PollCard key={poll.id} poll={poll} onDelete={fetchPolls} />
          ))}
        </div>
      )}

      {/* Create Poll Modal */}
      <PollCreator
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onPollCreated={fetchPolls}
      />
    </div>
  );
};

export default PollDashboard;
