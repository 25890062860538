import React, { useState, useEffect } from 'react';
import { Copy, Check } from 'lucide-react';
import { useAuth } from '../../../features/auth/contexts/AuthContext';
import { createMailtoLink } from '../../../features/auth/services/firestore';
import { SHORT_IO_API_KEY } from '../../../config/api';
import { APP_CONSTANTS } from '../../../config/constants';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../config/firebase';

const MailtoGenerator = ({ isOpen, onClose, onSave }) => {
    const [formData, setFormData] = useState({
      to: '',
      cc: '',
      bcc: '',
      subject: '',
      body: '',
      linkText: 'Send email',
      createShortUrl: false
    });
    const [copied, setCopied] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [truncationWarning, setTruncationWarning] = useState(false);
    const [mailtoLink, setMailtoLink] = useState('');
    const { user } = useAuth();
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
  
    const generateMailtoLink = (forShortUrl = false) => {
      let mailto = 'mailto:' + encodeURIComponent(formData.to);
      const params = [];

      if (formData.cc) params.push('cc=' + encodeURIComponent(formData.cc));
      if (formData.bcc) params.push('bcc=' + encodeURIComponent(formData.bcc));
      if (formData.subject) params.push('subject=' + encodeURIComponent(formData.subject));

      if (formData.body) {
        // Split long content into smaller chunks
        const MAX_CHUNK = 2500;
        const chunks = formData.body.match(new RegExp(`.{1,${MAX_CHUNK}}`, 'g')) || [formData.body];
        
        if (chunks.length > 1 && !forShortUrl) {
          // For multiple chunks, create a numbered subject
          const chunkSubject = `${formData.subject || 'Email'} (1/${chunks.length})`;
          params.push('subject=' + encodeURIComponent(chunkSubject));
          params.push('body=' + encodeURIComponent(chunks[0] + 
            `\n\n[Message continued in ${chunks.length - 1} more email(s)]`));
        } else {
          params.push('body=' + encodeURIComponent(formData.body));
        }
      }

      return mailto + (params.length > 0 ? '?' + params.join('&') : '');
    };
  
    // Check for truncation whenever body content changes
    useEffect(() => {
      const checkTruncation = async () => {
        if (formData.body) {
          try {
            const mailtoLink = await generateMailtoLink();
            const urlParams = new URLSearchParams(mailtoLink.split('?')[1] || '');
            const bodyContent = decodeURIComponent(urlParams.get('body') || '');
            
            const normalizedOriginal = formData.body.replace(/\s+/g, ' ').trim();
            const normalizedBody = bodyContent.replace(/\s+/g, ' ').trim();
            
            setTruncationWarning(normalizedOriginal !== normalizedBody);
          } catch (error) {
            console.error('Error checking truncation:', error);
          }
        } else {
          setTruncationWarning(false);
        }
      };
      
      checkTruncation();
    }, [formData.body]);
  
    const generateHtmlCode = () => {
      const link = generateMailtoLink();
      return `<a href="${link}" target="_blank">${formData.linkText}</a>`;
    };
  
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    };
  
    const shortenUrl = async (longUrl) => {
      if (!SHORT_IO_API_KEY) {
        console.error('API Key is missing');
        alert('Configuration error: API Key is missing');
        return '';
      }
  
      try {
        const requestBody = {
          domain: 'links.arabees.org',
          originalURL: longUrl,
          allowDuplicates: false
        };
  
        const response = await fetch('https://api.short.cm/links/public', {
          method: 'POST',
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': SHORT_IO_API_KEY
          },
          body: JSON.stringify(requestBody)
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          console.error('API Response:', errorData);
          throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        return data.shortURL;
      } catch (error) {
        console.error('Shortening error:', error);
        alert(`Failed to create short URL: ${error.message}`);
        return '';
      }
    };
  
    const handleCheckboxChange = (e) => {
      setFormData(prev => ({
        ...prev,
        createShortUrl: e.target.checked
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
  
      try {
        const mailtoLink = generateMailtoLink();
        let shortUrlData = null;
  
        if (formData.createShortUrl) {
          const data = await shortenUrl(mailtoLink);
          shortUrlData = {
            shortUrl: data.shortURL,
            linkId: data.idString
          };
        }
  
        const linkData = {
          to: formData.to,
          cc: formData.cc,
          bcc: formData.bcc,
          subject: formData.subject,
          body: formData.body,
          linkText: formData.linkText,
          shortUrl: shortUrlData?.shortUrl || null,
          linkId: shortUrlData?.linkId || null,
          createdAt: serverTimestamp()
        };
  
        await createMailtoLink(user.uid, linkData);
        
        if (onSave) {
          onSave();
        }
        
        onClose();
      } catch (error) {
        console.error('Error saving mailto link:', error);
        alert('Failed to save mailto link. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    };
  
    useEffect(() => {
      const generateLink = async () => {
        const link = await generateMailtoLink();
        setMailtoLink(link);
      };
      generateLink();
    }, [formData]);
  
    const generateAdditionalLinks = () => {
      if (!formData.body) return [];

      const MAX_CHUNK = 500;
      const chunks = formData.body.match(new RegExp(`.{1,${MAX_CHUNK}}`, 'g')) || [formData.body];
      
      if (chunks.length <= 1) return [];
      
      return chunks.slice(1).map((chunk, index) => {
        let link = 'mailto:' + encodeURIComponent(formData.to);
        const params = [];
        
        if (formData.cc) params.push('cc=' + encodeURIComponent(formData.cc));
        if (formData.bcc) params.push('bcc=' + encodeURIComponent(formData.bcc));
        params.push('subject=' + encodeURIComponent(`${formData.subject || 'Email'} (${index + 2}/${chunks.length})`));
        params.push('body=' + encodeURIComponent(chunk));
        
        return link + '?' + params.join('&');
      });
    };
  
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white dark:bg-[#102B4E] rounded-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto">
          <form onSubmit={handleSubmit}>
            <div className="space-y-6">
              <div className="bg-white dark:bg-[#102B4E] p-6 rounded-lg shadow">
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      To
                    </label>
                    <input
                      type="email"
                      name="to"
                      value={formData.to}
                      onChange={handleInputChange}
                      className="w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300"
                      placeholder="recipient@example.com"
                    />
                  </div>
  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Cc
                    </label>
                    <input
                      type="email"
                      name="cc"
                      value={formData.cc}
                      onChange={handleInputChange}
                      className="w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300"
                    />
                  </div>
  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Bcc
                    </label>
                    <input
                      type="email"
                      name="bcc"
                      value={formData.bcc}
                      onChange={handleInputChange}
                      className="w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300"
                    />
                  </div>
  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Subject
                    </label>
                    <input
                      type="text"
                      name="subject"
                      value={formData.subject}
                      onChange={handleInputChange}
                      className="w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300"
                    />
                  </div>
  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Body
                    </label>
                    <textarea
                      name="body"
                      value={formData.body}
                      onChange={handleInputChange}
                      rows="4"
                      className="w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300"
                    />
                  </div>
  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Link Text
                    </label>
                    <input
                      type="text"
                      name="linkText"
                      value={formData.linkText}
                      onChange={handleInputChange}
                      className="w-full rounded-md border border-gray-300 px-3 py-2 dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300"
                    />
                  </div>
                </div>
              </div>
  
              <div className="bg-white dark:bg-[#102B4E] p-6 rounded-lg shadow space-y-4">
                <div>
                  <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300 mb-2">Generated Mailto Link(s)</h3>
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        readOnly
                        value={generateMailtoLink()}
                        className="w-full rounded-md border border-gray-300 px-3 py-2 bg-gray-50 dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300"
                      />
                      <button
                        onClick={() => copyToClipboard(generateMailtoLink())}
                        className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-yellow-500"
                      >
                        {copied ? <Check size={20} /> : <Copy size={20} />}
                      </button>
                    </div>
                    {generateAdditionalLinks().map((link, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <input
                          type="text"
                          readOnly
                          value={link}
                          className="w-full rounded-md border border-gray-300 px-3 py-2 bg-gray-50 dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300"
                        />
                        <button
                          onClick={() => copyToClipboard(link)}
                          className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-yellow-500"
                        >
                          <Copy size={20} />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
  
                <div>
                  <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300 mb-2">HTML Code</h3>
                  <div className="flex items-center gap-2">
                    <input
                      type="text"
                      readOnly
                      value={generateHtmlCode()}
                      className="w-full rounded-md border border-gray-300 px-3 py-2 bg-gray-50 dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300"
                    />
                    <button
                      onClick={() => copyToClipboard(generateHtmlCode())}
                      className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-yellow-500"
                    >
                      {copied ? <Check size={20} /> : <Copy size={20} />}
                    </button>
                  </div>
                </div>
  
                <div className="flex items-center gap-2 mt-4">
                  <input
                    type="checkbox"
                    id="createShortUrl"
                    name="createShortUrl"
                    checked={formData.createShortUrl}
                    onChange={handleCheckboxChange}
                    className="rounded border-gray-300"
                  />
                  <label htmlFor="createShortUrl" className="text-sm text-gray-700 dark:text-gray-300">
                    Generate short URL when saving
                  </label>
                </div>
  
                {formData.createShortUrl && (
                  <div>
                    <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Short URL Preview
                    </h3>
                    <p className="text-sm text-gray-600 dark:text-gray-300">
                      A short URL will be generated when you save the form.
                    </p>
                  </div>
                )}
              </div>
            </div>
  
            <div className="flex justify-end gap-2 p-4 border-t">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
                disabled={isSubmitting}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark disabled:opacity-50"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };
  
  export default MailtoGenerator;