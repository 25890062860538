import axios from 'axios';
import { SHORTIO_CONFIG } from '../../../config/shortio';

// Use the full Cloud Function URL
const CLOUD_FUNCTION_URL = 'https://us-central1-arabees.cloudfunctions.net/shortIoProxy';

export const getLinkAnalytics = async (linkId) => {
  try {
    const response = await axios.get(
      CLOUD_FUNCTION_URL,
      {
        params: {
          linkId,
          period: 'last30',
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const shortenUrl = async (longUrl, title = '') => {
  if (!SHORTIO_CONFIG.API_KEY) {
    throw new Error('Short.io API key is not configured');
  }

  try {
    const data = {
      domain: SHORTIO_CONFIG.DOMAIN,
      originalURL: longUrl,
      title: title,
      allowDuplicates: false
    };

    const response = await fetch('https://api.short.io/links/public', {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': SHORTIO_CONFIG.API_KEY
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to create short URL');
    }

    const responseData = await response.json();
    return {
      shortUrl: responseData.shortURL,
      linkId: responseData.idString
    };
  } catch (error) {
    throw error;
  }
};

