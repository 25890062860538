import React, { useState } from 'react';
import { Plus, Minus, Send, Eye } from 'lucide-react';
import { useAuth } from '../../auth/contexts/AuthContext';
import { createPoll } from '../services/firestore';
import PollPreview from './PollPreview';

const PollCreator = ({ isOpen, onClose, onPollCreated }) => {
  const { user } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [formData, setFormData] = useState({
    question: '',
    options: ['', ''], // Minimum 2 options
    isAnonymous: false,
    allowMultipleAnswers: false
  });

  const handleAddOption = () => {
    if (formData.options.length < 10) { // Telegram max options limit
      setFormData(prev => ({
        ...prev,
        options: [...prev.options, '']
      }));
    }
  };

  const handleRemoveOption = (index) => {
    if (formData.options.length > 2) { // Maintain minimum 2 options
      setFormData(prev => ({
        ...prev,
        options: prev.options.filter((_, i) => i !== index)
      }));
    }
  };

  const handleOptionChange = (index, value) => {
    setFormData(prev => ({
      ...prev,
      options: prev.options.map((option, i) => i === index ? value : option)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      console.log('Form submitted with data:', formData);
      
      const filteredOptions = formData.options.filter(opt => opt.trim() !== '');
      if (filteredOptions.length < 2) {
        throw new Error('At least 2 non-empty options are required');
      }

      const pollData = {
        question: formData.question.trim(),
        options: filteredOptions,
        isAnonymous: formData.isAnonymous,
        allowMultipleAnswers: formData.allowMultipleAnswers,
        createdBy: user.uid,
        status: 'pending',
        totalVotes: 0,
        analytics: {
          optionVotes: {},
          votingTrends: []
        }
      };

      console.log('Creating poll with:', pollData);
      const pollId = await createPoll(pollData);
      console.log('Poll created successfully:', pollId);

      if (onPollCreated) {
        onPollCreated();
      }
      onClose();
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      alert(`Failed to create poll: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-darkbg rounded-lg max-w-2xl w-full p-6">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-6">
          Create New Poll
        </h2>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Question Input */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Question
            </label>
            <input
              type="text"
              value={formData.question}
              onChange={(e) => setFormData(prev => ({ ...prev, question: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md 
                       dark:bg-gray-800 dark:text-gray-100"
              required
              maxLength={300}
            />
          </div>

          {/* Options */}
          <div className="space-y-3">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Options
            </label>
            {formData.options.map((option, index) => (
              <div key={index} className="flex gap-2">
                <input
                  type="text"
                  value={option}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md 
                           dark:bg-gray-800 dark:text-gray-100"
                  required
                  maxLength={100}
                />
                {formData.options.length > 2 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveOption(index)}
                    className="p-2 text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                  >
                    <Minus size={20} />
                  </button>
                )}
              </div>
            ))}
            {formData.options.length < 10 && (
              <button
                type="button"
                onClick={handleAddOption}
                className="flex items-center gap-2 text-primary dark:text-yellow-500 
                         hover:text-primary-dark dark:hover:text-yellow-400"
              >
                <Plus size={20} />
                Add Option
              </button>
            )}
          </div>

          {/* Poll Settings */}
          <div className="space-y-3">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="isAnonymous"
                checked={formData.isAnonymous}
                onChange={(e) => setFormData(prev => ({ ...prev, isAnonymous: e.target.checked }))}
                className="rounded border-gray-300 dark:border-gray-600"
              />
              <label htmlFor="isAnonymous" className="text-sm text-gray-700 dark:text-gray-300">
                Anonymous Poll
              </label>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="allowMultipleAnswers"
                checked={formData.allowMultipleAnswers}
                onChange={(e) => setFormData(prev => ({ 
                  ...prev, 
                  allowMultipleAnswers: e.target.checked 
                }))}
                className="rounded border-gray-300 dark:border-gray-600"
              />
              <label htmlFor="allowMultipleAnswers" className="text-sm text-gray-700 dark:text-gray-300">
                Allow Multiple Answers
              </label>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end gap-4 mt-6">
            <button
              type="button"
              onClick={() => setShowPreview(true)}
              className="flex items-center gap-2 px-4 py-2 text-gray-700 dark:text-gray-300 
                       hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md"
            >
              <Eye size={20} />
              Preview
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 dark:text-gray-300 
                       hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="flex items-center gap-2 px-4 py-2 bg-primary dark:bg-yellow-500 
                       text-white dark:text-gray-900 rounded-md hover:bg-primary-dark 
                       dark:hover:bg-yellow-400 disabled:opacity-50"
            >
              <Send size={20} />
              {isSubmitting ? 'Creating...' : 'Create Poll'}
            </button>
          </div>
        </form>
      </div>

      {showPreview && (
        <PollPreview
          pollData={formData}
          onClose={() => setShowPreview(false)}
        />
      )}
    </div>
  );
};

export default PollCreator;
