import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import AnalyticsDashboard from '../components/AnalyticsDashboard';

const URLAnalyticsDetail = () => {
  const { urlId } = useParams();
  const navigate = useNavigate();
  const [urlData, setUrlData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUrlData = async () => {
      try {
        const docRef = doc(db, 'trackedUrls', urlId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setUrlData({ id: docSnap.id, ...docSnap.data() });
        } else {
          navigate('/analytics');
        }
      } catch (error) {
        console.error('Error fetching URL data:', error);
        navigate('/analytics');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUrlData();
  }, [urlId, navigate]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary dark:border-yellow-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-6">
      <button
        onClick={() => navigate('/analytics')}
        className="flex items-center gap-2 text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100 mb-6"
      >
        <ArrowLeft size={20} />
        Back to Dashboard
      </button>
      
      {urlData && (
        <AnalyticsDashboard 
          urlData={urlData}
          onDelete={() => navigate('/analytics')}
        />
      )}
    </div>
  );
};

export default URLAnalyticsDetail;
