import React, { useState, useEffect } from 'react';
import { auth } from '../../../config/firebase';
import { updateProfile, updateEmail, updatePassword } from 'firebase/auth';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { AlertCircle, Save, User, Mail, Lock } from 'lucide-react';
import { resetMailtoLinksCount } from '../services/firestore';

const UserInfo = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [userData, setUserData] = useState({
    displayName: '',
    email: '',
    password: '',
    confirmPassword: '',
    createdAt: null,
    lastLogin: null,
    mailtoLinksGenerated: 0,
    urlTracksGenerated: 0
  });

  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          navigate('/login');
          return;
        }

        await resetMailtoLinksCount(user.uid);

        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const data = userDoc.data();

        const urlTracksRef = collection(db, 'trackedUrls');
        const urlTracksQuery = query(urlTracksRef, where('userId', '==', user.uid));
        const urlTracksSnapshot = await getDocs(urlTracksQuery);

        console.log('Raw timestamps:', {
          createdAt: data?.createdAt,
          lastLogin: data?.lastLogin,
          createdAtDate: data?.createdAt?.toDate(),
          lastLoginDate: data?.lastLogin?.toDate()
        });

        setUserData({
          displayName: user.displayName || '',
          email: user.email || '',
          password: '',
          confirmPassword: '',
          createdAt: data?.createdAt?.toDate(),
          lastLogin: data?.lastLogin?.toDate(),
          mailtoLinksGenerated: data?.mailtoLinksGenerated || 0,
          urlTracksGenerated: urlTracksSnapshot.size || 0
        });
        setLoading(false);
      } catch (error) {
        setError('Failed to load user data');
        setLoading(false);
      }
    };

    fetchUserData();
  }, [navigate, db]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const user = auth.currentUser;
      const updates = [];

      if (userData.displayName !== user.displayName) {
        updates.push(updateProfile(user, { displayName: userData.displayName }));
      }

      if (userData.email !== user.email) {
        updates.push(updateEmail(user, userData.email));
      }

      if (userData.password) {
        if (userData.password !== userData.confirmPassword) {
          setError('Passwords do not match');
          return;
        }
        updates.push(updatePassword(user, userData.password));
      }

      await Promise.all(updates);
      setSuccess('Profile updated successfully');
      
      // Clear password fields after successful update
      setUserData(prev => ({
        ...prev,
        password: '',
        confirmPassword: ''
      }));
    } catch (error) {
      setError('Failed to update profile: ' + error.message);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    
    return new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short'
    }).format(timestamp);
  };

  if (loading) {
    return <div className="flex justify-center items-center h-64">Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-4 sm:p-6 lg:p-8">
      <h2 className="text-3xl font-bold mb-8 text-primary dark:text-yellow-500">User Profile</h2>
      
      {/* User Stats - Updated Grid */}
      <div className="mb-10 grid grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-white dark:bg-[#102B4E] p-6 rounded-xl shadow-sm transition-shadow hover:shadow-md">
          <h3 className="text-sm font-semibold text-gray-500 dark:text-yellow-500 mb-2">Member Since</h3>
          <p className="text-base sm:text-sm text-gray-700 dark:text-gray-300 break-words">
            {formatDate(userData.createdAt)}
          </p>
        </div>
        <div className="bg-white dark:bg-[#102B4E] p-6 rounded-xl shadow-sm transition-shadow hover:shadow-md">
          <h3 className="text-sm font-semibold text-gray-500 dark:text-yellow-500 mb-2">Last Login</h3>
          <p className="text-base sm:text-sm text-gray-700 dark:text-gray-300 break-words">
            {formatDate(userData.lastLogin)}
          </p>
        </div>
        <div className="bg-white dark:bg-[#102B4E] p-6 rounded-xl shadow-sm transition-shadow hover:shadow-md">
          <h3 className="text-sm font-semibold text-gray-500 dark:text-yellow-500 mb-2">Mailto Links</h3>
          <p className="text-base sm:text-sm text-gray-700 dark:text-gray-300">
            {userData.mailtoLinksGenerated}
          </p>
        </div>
        <div className="bg-white dark:bg-[#102B4E] p-6 rounded-xl shadow-sm transition-shadow hover:shadow-md">
          <h3 className="text-sm font-semibold text-gray-500 dark:text-yellow-500 mb-2">URL Tracks</h3>
          <p className="text-base sm:text-sm text-gray-700 dark:text-gray-300">
            {userData.urlTracksGenerated}
          </p>
        </div>
      </div>

      {/* Update Form - Enhanced Styling */}
      <form onSubmit={handleSubmit} className="space-y-8 bg-white dark:bg-[#102B4E] p-6 sm:p-8 rounded-xl shadow-sm">
        {error && (
          <div className="flex items-center gap-3 text-red-600 bg-red-50 dark:bg-red-900/20 p-4 rounded-lg">
            <AlertCircle className="flex-shrink-0" size={24} />
            <span className="text-sm sm:text-base">{error}</span>
          </div>
        )}
        
        {success && (
          <div className="flex items-center gap-3 text-green-600 bg-green-50 dark:bg-green-900/20 p-4 rounded-lg">
            <Save className="flex-shrink-0" size={24} />
            <span className="text-sm sm:text-base">{success}</span>
          </div>
        )}

        <div className="grid gap-6 sm:gap-8 md:grid-cols-2">
          <div className="space-y-2">
            <label className="flex items-center text-sm font-medium text-gray-700 dark:text-yellow-500">
              <User className="mr-2 flex-shrink-0" size={18} />
              Display Name
            </label>
            <input
              type="text"
              value={userData.displayName}
              onChange={(e) => setUserData({ ...userData, displayName: e.target.value })}
              className="w-full p-3 border rounded-lg dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300 focus:ring-2 focus:ring-primary/50 focus:border-primary transition-shadow"
            />
          </div>

          <div className="space-y-2">
            <label className="flex items-center text-sm font-medium text-gray-700 dark:text-yellow-500">
              <Mail className="mr-2 flex-shrink-0" size={18} />
              Email
            </label>
            <input
              type="email"
              value={userData.email}
              onChange={(e) => setUserData({ ...userData, email: e.target.value })}
              className="w-full p-3 border rounded-lg dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300 focus:ring-2 focus:ring-primary/50 focus:border-primary transition-shadow"
            />
          </div>

          <div className="space-y-2">
            <label className="flex items-center text-sm font-medium text-gray-700 dark:text-yellow-500">
              <Lock className="mr-2 flex-shrink-0" size={18} />
              New Password
            </label>
            <input
              type="password"
              value={userData.password}
              onChange={(e) => setUserData({ ...userData, password: e.target.value })}
              className="w-full p-3 border rounded-lg dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300 focus:ring-2 focus:ring-primary/50 focus:border-primary transition-shadow"
            />
          </div>

          <div className="space-y-2">
            <label className="flex items-center text-sm font-medium text-gray-700 dark:text-yellow-500">
              <Lock className="mr-2 flex-shrink-0" size={18} />
              Confirm New Password
            </label>
            <input
              type="password"
              value={userData.confirmPassword}
              onChange={(e) => setUserData({ ...userData, confirmPassword: e.target.value })}
              className="w-full p-3 border rounded-lg dark:bg-[#0A1929] dark:border-gray-600 dark:text-gray-300 focus:ring-2 focus:ring-primary/50 focus:border-primary transition-shadow"
            />
          </div>
        </div>

        <button
          type="submit"
          className="w-full sm:w-auto min-w-[200px] mt-8 bg-primary text-white py-3 px-6 rounded-lg hover:bg-primary-dark transition duration-200 font-medium text-sm sm:text-base"
        >
          Update Profile
        </button>
      </form>
    </div>
  );
};

export default UserInfo;
