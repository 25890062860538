import React from 'react';
import { X } from 'lucide-react';

const PollPreview = ({ pollData, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-darkbg rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
            Poll Preview
          </h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <X size={20} />
          </button>
        </div>

        <div className="space-y-4">
          {/* Question */}
          <div className="text-gray-900 dark:text-gray-100 font-medium">
            {pollData.question}
          </div>

          {/* Options */}
          <div className="space-y-2">
            {pollData.options.map((option, index) => (
              <button
                key={index}
                className="w-full px-4 py-2 text-left text-gray-700 dark:text-gray-300 
                         bg-gray-100 dark:bg-gray-800 rounded-md hover:bg-gray-200 
                         dark:hover:bg-gray-700 transition-colors"
              >
                {option}
              </button>
            ))}
          </div>

          {/* Poll Settings */}
          <div className="text-sm text-gray-500 dark:text-gray-400 space-y-1">
            {pollData.isAnonymous && <div>Anonymous poll</div>}
            {pollData.allowMultipleAnswers && <div>Multiple answers allowed</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PollPreview;
