import React, { useState } from 'react';
import { Copy, Check, Trash2, ExternalLink } from 'lucide-react';
import DeleteConfirmationModal from './DeleteConfirmationModal';

const MailtoCard = ({ link, onDelete }) => {
  const [copied, setCopied] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const totalClicks = link.analytics?.totalClicks || 0;
  const humanClicks = link.analytics?.humanClicks || 0;
  const clicksChange = link.analytics?.totalClicksChange || '0';

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const formatDateTime = (timestamp) => {
    return new Date(timestamp.toDate()).toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleDelete = async () => {
    try {
      await onDelete(link.id);
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting link:', error);
      alert('Failed to delete link. Please try again.');
    }
  };

  return (
    <>
      <div className="bg-white dark:bg-[#102B4E] p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
        <div className="space-y-5">
          {/* Header - improved spacing and alignment */}
          <div className="flex justify-between items-center">
            <h3 className="font-semibold text-lg text-gray-900 dark:text-gray-100 truncate max-w-[70%]">
              {link.subject || 'No Subject'}
            </h3>
            <div className="flex items-center space-x-3">
              <span className="text-sm text-gray-500 dark:text-yellow-500 whitespace-nowrap">
                {formatDateTime(link.createdAt)}
              </span>
              <button
                onClick={() => setShowDeleteModal(true)}
                className="p-1.5 rounded-full text-gray-400 hover:text-red-500 hover:bg-red-50 dark:hover:bg-red-900/20 transition-all"
                title="Delete link"
              >
                <Trash2 size={16} />
              </button>
            </div>
          </div>
          
          {/* Recipients - improved spacing and typography */}
          <div className="text-sm text-gray-600 dark:text-gray-300 space-y-2">
            <p className="flex">
              <span className="font-medium w-12">To:</span>
              <span className="flex-1">{link.to}</span>
            </p>
            {link.cc && (
              <p className="flex">
                <span className="font-medium w-12">Cc:</span>
                <span className="flex-1">{link.cc}</span>
              </p>
            )}
            {link.bcc && (
              <p className="flex">
                <span className="font-medium w-12">Bcc:</span>
                <span className="flex-1">{link.bcc}</span>
              </p>
            )}
          </div>

          {/* Body - improved text display */}
          {link.body && (
            <div className="space-y-2">
              <p className={`text-sm text-gray-600 dark:text-gray-300 whitespace-pre-wrap ${!isExpanded && 'line-clamp-3'}`}>
                {link.body}
              </p>
              {link.body.length > 200 && (
                <button
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="text-sm font-medium text-primary hover:text-primary-dark dark:text-yellow-500 dark:hover:text-yellow-400 transition-colors"
                >
                  {isExpanded ? 'Show less' : 'Show more'}
                </button>
              )}
            </div>
          )}

          {/* Short URL Section with Analytics */}
          {link.shortUrl && (
            <div className="flex flex-col gap-3 pt-4 mt-4 border-t dark:border-gray-700">
              <div className="flex items-center gap-2 flex-wrap">
                <div className="flex-1 min-w-0 flex items-center gap-2">
                  <span className="font-mono text-sm text-gray-500 dark:text-gray-400 truncate">
                    {link.shortUrl}
                  </span>
                  <div className="flex items-center gap-1 px-2 py-0.5 bg-gray-100 dark:bg-[#0a213e] rounded-full shrink-0">
                    <span className="text-sm font-semibold text-gray-700 dark:text-gray-200">
                      {totalClicks}
                    </span>
                    <span className="text-xs text-gray-500 dark:text-gray-400">
                      clicks
                    </span>
                    {humanClicks > 0 && (
                      <span className="text-xs text-gray-500 dark:text-gray-400">
                        ({humanClicks} human)
                      </span>
                    )}
                    {clicksChange !== '0' && clicksChange !== '999' && (
                      <span className={`text-xs ${clicksChange.startsWith('-') ? 'text-red-500' : 'text-green-500'}`}>
                        ({clicksChange}%)
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-1">
                  <button
                    onClick={() => copyToClipboard(link.shortUrl)}
                    className="text-primary dark:text-yellow-500 hover:opacity-80 p-1"
                    title="Copy short URL"
                  >
                    {copied === link.id ? <Check size={16} /> : <Copy size={16} />}
                  </button>
                  <a
                    href={link.shortUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary dark:text-yellow-500 hover:opacity-80 p-1"
                    title="Open short URL"
                  >
                    <ExternalLink size={16} />
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <DeleteConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        linkSubject={link.subject}
      />
    </>
  );
};

export default MailtoCard;
