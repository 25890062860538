import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { auth } from './config/firebase';
import LoginPage from './features/auth/components/LoginPage';
import Dashboard from './features/dashboard/pages/Dashboard';
import Navbar from './components/Navbar';
import MailtoDashboard from './features/mailto/pages/MailtoDashboard';
import { AuthProvider } from './features/auth/contexts/AuthContext';
import URLAnalyticsDashboard from './features/url-analytics/pages/URLAnalyticsDashboard';
import URLAnalyticsDetail from './features/url-analytics/pages/URLAnalyticsDetail';
import UserInfo from './features/auth/components/UserInfo';
import PollDashboard from './features/polls/components/PollDashboard';
import PollAnalytics from './features/polls/components/PollAnalytics';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PollProvider } from './features/polls/contexts/PollContext';

const App = () => {
  const [user, setUser] = useState(null);
  const [darkMode, setDarkMode] = useState(() => {
    return localStorage.getItem('darkMode') === 'true';
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  const handleLogout = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <AuthProvider>
      <PollProvider>
        <Router>
          <div className="min-h-screen bg-gray-50 dark:bg-primary-dark">
            {user && (
              <Navbar 
                user={user} 
                onLogout={handleLogout}
                darkMode={darkMode}
                onToggleDarkMode={() => setDarkMode(!darkMode)}
              />
            )}
            <Routes>
              <Route 
                path="/login" 
                element={user ? <Navigate to="/" /> : <LoginPage darkMode={darkMode} />} 
              />
              <Route 
                path="/" 
                element={user ? <Dashboard /> : <Navigate to="/login" />} 
              />
              <Route 
                path="/mailto" 
                element={user ? <MailtoDashboard /> : <Navigate to="/login" />} 
              />
              <Route 
                path="/url-analytics" 
                element={user ? <URLAnalyticsDashboard /> : <Navigate to="/login" />} 
              />
              <Route 
                path="/profile"
                element={user ? <UserInfo /> : <Navigate to="/login" />}
              />
              <Route 
                path="/polls" 
                element={user ? <PollDashboard /> : <Navigate to="/login" />} 
              />
              <Route 
                path="/polls/:pollId" 
                element={user ? <PollAnalytics /> : <Navigate to="/login" />} 
              />
              <Route path="/analytics" element={<URLAnalyticsDashboard />} />
              <Route path="/analytics/:urlId" element={<URLAnalyticsDetail />} />
            </Routes>
          </div>
          <ToastContainer />
        </Router>
      </PollProvider>
    </AuthProvider>
  );
};

export default App;